const initialState = {
  id: null,
  isLoading: false,
  isNewConference: true,
  currentStep: 0,

  // STEP #1
  tab: '1',
  title: '',
  description: '',
  prices_dates: '',
  schedule: '',
  emailString: '',
  isPreReg: false,
  regOpen: null,
  regClose: null,
  notificationOpen: null,
  notificationClose: null,
  availableTickets: 80,
  sicknessVisible: false,

  // STEP #2
  questions: [],

  // STEP #3
  attachments: [],
};

export default initialState;
