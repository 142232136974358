export default {
  translation: {
    usermenu: {
      change_password: 'Jelszó módosítása',
      logout: 'Kijelentkezés',
    },

    pages: {
      login: {
        email: 'E-mail cím',
        password: 'Jelszó',
        login: 'BEJELENTKEZÉS',
        registration: 'Kattintson ide új fiók regisztrációjához',
        passReset: 'Elfelejtett jelszó visszaállítása',
        info: 'Kérjük, jelentkezzen be e-mail címe és jelszava beütésével.\nAmennyiben még nem rendelkezik rendszerünkben fiókkal,',
        errors: {
          emptyEmail: 'Hiányzik az email cím',
          emptyPassword: 'Hiányzik a jelszó',
        },
      },

      signup: {
        email: 'Email',
        password: 'Jelszó',
        show: 'megjelenít',
        hide: 'elrejt',
        signup: 'Regisztráció',
        required: {
          please_input_your_e_mail: 'Kérem adja meg az e-mail címét!',
          please_input_your_password: 'Kérem adja meg a jelszavát!',
          the_input_is_not_valid_e_mail: 'Kérem érvényes e-mail címet adjon meg!',
        },
      },

      profile: {
        changePassword: {
          current_password: 'Jelenlegi jelszó',
          new_password: 'Új jelszó',
          retype_password: 'Új jelszó megerősítése',
          change_password: 'Jelszó módosítása',
        },

        forgotten: {
          restore: 'Jelszó helyreállítása',
        },
      },

      dashboard: {
        read_more: 'Tovább',
      },
    },
  },
};
