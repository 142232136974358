import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import AttachmentBlock from './AttachmentBlock/AttachmentBlock';
import './conferenceattachments.scss';


class ConferenceAttachments extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddAttachment = this.handleAddAttachment.bind(this);
  }

  handleAddAttachment() {
    this.props.addAttachment({
      id: new Date().getTime(),
      title: '',
      isRequired: false,
      isSaved: false,
    });
  }

  render() {
    return (
      <div className="form-attachments">
        {this.props.attachments.map((attachment, id) => (
          <AttachmentBlock
            key={attachment.id}
            attachment={attachment}
            isFirst={id === 0}
            isLast={id === this.props.attachments.length - 1}
            updateAttachment={this.props.updateAttachment}
            moveAttachment={this.props.moveAttachment}
            deleteAttachment={this.props.deleteAttachment}
          />
        ))}

        <div className="form-attachments-action">
          <Button
            size="large"
            type="dashed"
            className="add-new-attachment"
            onClick={this.handleAddAttachment}
          >
            Új feltöltési mező hozzáadása
          </Button>
        </div>
      </div>
    );
  }
}

ConferenceAttachments.defaultProps = {
  attachments: [],
};

ConferenceAttachments.propTypes = {
  addAttachment: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  moveAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape()),
};

export default ConferenceAttachments;
