import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { userSignup } from 'actions/appActions';
import RegistrationForm from './RegistrationForm/RegistrationForm';


class Registration extends React.Component {
  componentWillMount() {
    const token = localStorage.getItem('token');
    if (token) {
      this.props.restoreUserByToken('/turnusok');
    }
  }

  handleSubmit = (signupValues) => {
    this.props.userSignup(
      signupValues.email,
      signupValues.password,
    );
  }

  render() {
    return (
      <div className="page page-registration page-form">
        <div className="page-header">
          <div className="page-header-wrap">Regisztrációs űrlap</div>
        </div>

        <div className="page-content">
          <div className="page-content-wrap">
            <div className="container">
              <Row type="flex" justify="center" align="top">
                <Col span={24}>
                  <RegistrationForm handleSubmit={this.handleSubmit} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Registration.propTypes = {
  userSignup: PropTypes.func.isRequired,
  restoreUserByToken: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  userSignup,
};

export default connect(null, mapDispatchToProps)(Registration);
