import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, Tabs } from 'antd';
import Spinner from 'react-spinkit';
import ConferenceBoard from 'components/ConferenceBoard/ConferenceBoard';
import { host, history } from 'store/store';
import { resetConferenceFields } from 'actions/newconferenceActions';
import {
  getAdminConferences,
  editConference, cloneConference, deleteConference,
  createWebExport,
} from 'actions/appActions';
// import './admin.scss';

const { TabPane } = Tabs;


class Admin extends React.Component {
  componentWillMount() {
    this.props.dispatchGetAdminConferences();
  }

  componentDidMount() {
    document.getElementsByClassName('ant-layout-content')[0].scrollTop = 0;
  }

  handleCreateConference = () => {
    this.props.dispatchResetConferenceFields();
    history.push('/admin/conferences/create');
  }

  handleActionButtonClick = (id, action) => {
    switch (action) {
      case 'profile': {
        history.push(`/admin/conferences/${id}/profile`);
        break;
      }

      case 'webexport': {
        window.open(`${host}/conferences/webexport?id=${id}&token=${localStorage.getItem('token')}`);
        break;
      }

      case 'xlsexport': {
        window.open(`${host}/conferences/xlsexport?id=${id}&token=${localStorage.getItem('token')}`);
        break;
      }

      case 'attachexport': {
        window.open(`${host}/conferences/zipexport?id=${id}&token=${localStorage.getItem('token')}`);
        break;
      }

      case 'clone': {
        Modal.confirm({
          title: 'Biztosan klónozni kívánja a konferenciát?',
          okText: 'Esemény klónozása',
          cancelText: 'Mégsem',
          onOk: () => {
            this.props.dispatchCloneConference(id);
          },
        });
        break;
      }

      case 'delete': {
        Modal.confirm({
          title: 'Biztosan törölni szeretné ezt az eseményt?',
          content: 'Az esemény törlésével elvesznek a kérdőívhez benyújtott válaszok is.',
          okText: 'Esemény törlése',
          cancelText: 'Mégsem',
          onOk: () => {
            this.props.dispatchDeleteConference(id);
          },
        });
        break;
      }

      case 'edit': {
        this.props.dispatchEditConference(id);
        break;
      }

      default:
    }
  }

  render() {
    if (!this.props.user.admin) {
      history.push('/turnusok');
    }

    return (
      <div className="page page-admin-dashboard">
        <div className="page-header">
          <div className="page-header-wrap">
            {`TÁBOR TURNUSOK ${new Date().getFullYear()} (admin)`}
          </div>
        </div>

        <div className="page-actions">
          <div className="page-action-button">
            <Button
              type="primary"
              icon="plus"
              size="large"
              onClick={this.handleCreateConference}
            >
              Esemény létrehozása
            </Button>
          </div>
        </div>

        {this.props.adminconferences === null
          ? (
            <div className="loader-container">
              <Spinner name="ball-beat" color="#23b7e5" />
            </div>
          )
          : (
            <div className="conferences-tabs">
              <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
                <TabPane tab="Előregisztráció" key="1">
                  <ConferenceBoard
                    conferences={this.props.adminconferences}
                    tab="3"
                    deleteConference={this.props.dispatchDeleteConference}
                    actionButtons={[
                      { label: 'Adatlap', action: 'profile' },
                      { label: 'Web export', action: 'webexport' },
                      { label: 'XLS export', action: 'xlsexport' },
                      { label: 'Szerkesztés', action: 'edit' },
                      { label: 'Klónozás', action: 'clone' },
                      { label: 'Törlés', action: 'delete' },
                    ]}
                    onClick={this.handleActionButtonClick}
                    withDropdown
                  />
                </TabPane>

                <TabPane tab="Tanulók" key="2">
                  <ConferenceBoard
                    conferences={this.props.adminconferences}
                    tab="1"
                    deleteConference={this.props.dispatchDeleteConference}
                    actionButtons={[
                      { label: 'Adatlap', action: 'profile' },
                      { label: 'Web export', action: 'webexport' },
                      { label: 'XLS export', action: 'xlsexport' },
                      { label: 'Csatolmányok exportja', action: 'attachexport' },
                      { label: 'Szerkesztés', action: 'edit' },
                      { label: 'Klónozás', action: 'clone' },
                    ]}
                    onClick={this.handleActionButtonClick}
                    withDropdown
                  />
                </TabPane>

                <TabPane tab="Kísérők" key="3">
                  <ConferenceBoard
                    conferences={this.props.adminconferences}
                    tab="2"
                    deleteConference={this.props.dispatchDeleteConference}
                    actionButtons={[
                      { label: 'Adatlap', action: 'profile' },
                      { label: 'Web export', action: 'webexport' },
                      { label: 'XLS export', action: 'xlsexport' },
                      { label: 'Csatolmányok exportja', action: 'attachexport' },
                      { label: 'Szerkesztés', action: 'edit' },
                      { label: 'Klónozás', action: 'clone' },
                      { label: 'Törlés', action: 'delete' },
                    ]}
                    onClick={this.handleActionButtonClick}
                    withDropdown
                  />
                </TabPane>
              </Tabs>
            </div>
          )
        }
      </div>
    );
  }
}

Admin.defaultProps = {
  adminconferences: null,
  user: {
    admin: false,
  },
};

Admin.propTypes = {
  dispatchGetAdminConferences: PropTypes.func.isRequired,
  dispatchEditConference: PropTypes.func.isRequired,
  dispatchCloneConference: PropTypes.func.isRequired,
  dispatchDeleteConference: PropTypes.func.isRequired,
  dispatchResetConferenceFields: PropTypes.func.isRequired,
  user: PropTypes.shape({
    admin: PropTypes.bool,
  }),
  adminconferences: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = store => ({
  adminconferences: store.conferences.adminconferences,
});
const mapDispatchToProps = {
  dispatchGetAdminConferences: getAdminConferences,
  dispatchEditConference: editConference,
  dispatchCloneConference: cloneConference,
  dispatchDeleteConference: deleteConference,
  dispatchResetConferenceFields: resetConferenceFields,
  dispatchCreateWebExport: createWebExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
