import React from 'react';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';

const ForgottenPasswordSent = () => (
  <div className="page page-forgotten-password-sent page-slim">
    <Alert
      message="Jelszó helyreállítása"
      description="A jelszó helyreállításához szükséges linket megküldtük a megadott email címre."
      type="success"
      className="password-reset-alert"
      showIcon
    />
    <Link to="/" style={{ display: 'block', textAlign: 'center' }}>Vissza a bejelentkezéshez</Link>
  </div>
);

export default ForgottenPasswordSent;
