/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Alert, Icon, Button, Collapse, Input, Tooltip, Modal, Switch } from 'antd';
import swal from 'sweetalert';
import { history } from 'store/store';
import ConfereceForm from 'components/ConferenceForm/ConferenceForm';
import ConferenceAttachment from 'components/ConferenceAttachment/ConferenceAttachment';
import { getConferenceForm, getConferenceAttachments } from 'services/api';
import { getKepviseloIds } from 'utils/utils';
import {
  getConferences,
  getConference,
  applyForConference,
  updateApplication,
  cancelApplication,
  getApplication,
} from 'actions/appActions';
import './conferenceapply.scss';

const { TextArea } = Input;
const { Panel } = Collapse;
const { confirm } = Modal;
const tab = {
  1: 'Tanuló',
  2: 'Kísérő',
  3: 'Képviselő',
};


class ConferenceAdminApply extends React.Component {
  constructor(props) {
    super(props);

    let conference;
    let currentProfile = null;

    if (this.props.conferences === null) {
      this.props.dispatchGetConferences();
    } else {
      conference = _.find(this.props.conferences, { id: this.props.match.params.id });

      if (conference === undefined) {
        history.push('/admin/conferences');
      }
    }

    if (this.props.currentProfile !== null) {
      if (this.props.currentProfile.id !== this.props.match.params.id) {
        this.props.dispatchGetConference(this.props.match.params.id);
      } else {
        ({ currentProfile } = this.props);
      }
    } else {
      this.props.dispatchGetConference(this.props.match.params.id);
    }

    this.props.dispatchGetApplication(this.props.match.params.id, this.props.match.params.applicationId);

    this.state = {
      currentProfile,
      currentApplication: null,
      activePanel: ['2'],
      email: this.props.user.email,
      firstName: '',
      lastName: '',
      questions: null,
      attachments: null,
      answers: null,
      isReady: false,
      files: [],
      missingFields: [],
      missingAttachments: [],
      trySumbit: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentProfile !== this.state.currentProfile) {
      this.setState({
        currentProfile: nextProps.currentProfile,
      });
    }

    if (nextProps.currentApplication !== this.state.currentApplication) {
      this.setState({
        activePanel: ['2', '3'],
        email: nextProps.currentApplication.user.email || '',
        lastName: nextProps.currentApplication.user.lastName || '',
        firstName: nextProps.currentApplication.user.firstName || '',
        haveDisease: nextProps.currentApplication.user.have_disease === '1',
        disease: nextProps.currentApplication.user.disease || '',
        currentApplication: nextProps.currentApplication,
        files: nextProps.currentApplication.files,
      });
    }
  }

  getConferenceForm = () => {
    getConferenceForm(this.state.currentProfile.id).then((response) => {
      const questions = [];
      response.data.forEach((question) => {
        questions.push(this.processFormData(question));
      });

      this.setState({
        questions,
      });
    });
  }

  getConferenceAttachments = () => {
    getConferenceAttachments(this.state.currentProfile.id).then((response) => {
      const attachments = [];

      response.data.forEach((attachment) => {
        attachments.push({
          id: parseInt(attachment.id, 10),
          title: attachment.name,
          isRequired: attachment.required,
          isUploaded: !_.findIndex(this.state.files, { field: 7 }) === attachment.id,
          htmlContent: null,
        });
      });

      this.setState({
        attachments,
      });
    });
  }

  processFormData = (question) => {
    const processedQuestion = question;

    switch (question.type) {
      // text input
      case '1': {
        processedQuestion.type = 'text';
        break;
      }

      // checkbox input
      case '2': {
        processedQuestion.type = 'checkbox';
        break;
      }

      // dropdown input
      case '3': {
        processedQuestion.type = 'dropdown';
        break;
      }

      // textarea input
      case '4': {
        processedQuestion.type = 'textarea';
        break;
      }

      // date input
      case '5': {
        processedQuestion.type = 'date';
        break;
      }

      default: break;
    }

    if (question.options.length !== 0) {
      const optionValues = [];

      question.options.forEach((option) => {
        optionValues.push({
          id: option.value,
          value: option.text,
        });
      });

      processedQuestion.options = optionValues;
    }

    return processedQuestion;
  }

  handleAttachmentRemove = (attachmentId) => {
    const { files } = this.state;
    _.remove(files, { field: `${attachmentId}` });
    this.setState({
      files,
    }, () => {
      this.handleAttachmentUpload(attachmentId, null, false);
    });
  }

  handleAttachmentUpload = (id, innerHTML, isUploaded) => {
    const { attachments } = this.state;
    const index = attachments.findIndex(attachment => attachment.id === id);

    attachments[index].isUploaded = isUploaded;
    attachments[index].htmlContent = innerHTML;

    this.setState({
      attachments,
    }, () => {
      this.checkAnswers(this.state.answers);
    });
  }

  handleCancelApply = () => {
    swal({
      title: 'Biztos benne hogy törli a jelentkezését?',
      icon: 'warning',
      buttons: {
        cancel: 'Mégsem',
        confirm: 'Jelentkezés törlése',
      },
      dangerMode: true,
    })
      .then((willCancel) => {
        if (willCancel) {
          this.props.dispatchCancelApplication(this.props.match.params.id, this.props.match.params.applicationId);
        }
      });
  }

  checkAnswers = (answers) => {
    let isReady = true;
    let index = 0;
    const missingFields = [];

    _.forIn(answers, (value) => {
      if (value.required === true
        && ((value.answer === null || value.answer === '')
        || (Array.isArray(value.answer) && (value.answer.length === 1 && value.answer[0] === '')))
      ) {
        missingFields.push(this.state.questions[index].title);
        isReady = false;
      }
      index += 1;
    });

    this.setState({
      answers,
      missingFields,
      isReady: this.checkAttachments() && isReady,
    });
  }

  checkAttachments = () => {
    let isReady = true;
    const missingAttachments = [];

    _.forIn(this.state.attachments, (attachment) => {
      if (attachment.isRequired === '1' && attachment.isUploaded === false) {
        missingAttachments.push(attachment.title);
        isReady = false;
      }
    });

    this.setState({
      missingAttachments,
    });

    return isReady;
  }

  showDeleteConfirm = (attachmentId) => {
    const { handleAttachmentRemove } = this;
    confirm({
      title: 'Biztosan törölni szeretné ezt a mellékletet?',
      okText: 'Melléklet törlése',
      okType: 'alert',
      cancelText: 'Mégsem',
      onOk() {
        handleAttachmentRemove(attachmentId);
      },
    });
  }

  renderMissingFields = titles => (
    <ul>
      {titles.map(title => (<li className="missing-fild-liest-item">{title}</li>))}
    </ul>
  );

  renderUploadedButtons = (id, title, isRequired, url) => {
    const { attachments } = this.state;
    const index = _.findIndex(attachments, { id });
    attachments[index].isUploaded = true;

    return (
      <div key={id} className="conference-attachment">
        <div className="conference-attachment-header">
          <div className="attachment-title">
            {title}
            {isRequired === '1'
              ? (
                <Tooltip placement="right" title="Kötelező melléklet">
                  <span className="attachment-required">*</span>
                </Tooltip>
              )
              : null
            }
          </div>
        </div>
        <div className="conference-attachment-body">
          <Icon
            type="check-circle"
            style={{
              color: '#2ecc71',
              fontSize: '38px',
              marginBottom: '15px',
            }}
          />
          <Button
            icon="eye-o"
            onClick={() => {
              window.open(url, '_blank');
            }}
          >
            Megtekintés
          </Button>

          <Button
            type="danger"
            icon="delete"
            onClick={() => {
              this.showDeleteConfirm(id);
            }}
          >
            Törlés
          </Button>
        </div>
      </div>
    );
  }

  renderUploadedImage = (id, title, isRequired) => (
    <div key={id} className="conference-attachment">
      <div className="conference-attachment-header">
        <div className="attachment-title">
          {title}
          {isRequired === '1'
            ? (
              <Tooltip placement="right" title="Kötelező melléklet">
                <span className="attachment-required">*</span>
              </Tooltip>
            )
            : null
          }
        </div>
      </div>
      <div className="conference-attachment-body">
        {/*
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        */}
        <Icon
          type="check-circle"
          style={{
            color: '#2ecc71',
            fontSize: '38px',
            marginBottom: '15px',
          }}
        />
        <Button
          type="danger"
          icon="delete"
          onClick={() => {
            this.showDeleteConfirm(id);
          }}
        >
          Törlés
        </Button>
      </div>
    </div>
  );

  renderIsUploaded = (isUploaded, id, title, isRequired, htmlContent) => (
    isUploaded
      ? this.renderUploadedImage(id, title, isRequired, htmlContent)
      : (
        <ConferenceAttachment
          key={id}
          id={id}
          conferenceId={parseInt(this.state.currentProfile.id, 10)}
          applicationId={parseInt(this.props.match.params.applicationId, 10)}
          title={title}
          isRequired={Boolean(Number(isRequired))}
          onComplete={(attachmentId, innerHTML) => {
            this.handleAttachmentUpload(attachmentId, innerHTML, true);
          }}
          onError={(attachmentId) => {
            this.handleAttachmentRemove(attachmentId);
          }}
        />
      )
  );

  render() {
    if (!this.props.user.admin) {
      history.push('/turnusok');
    }

    if (this.state.currentProfile === null) return null;

    if (this.state.questions === null) {
      this.getConferenceForm();
      return null;
    }

    if (this.state.attachments === null) {
      this.getConferenceAttachments();
      return null;
    }

    const {
      name,
      description,
      prices_dates, // eslint-disable-line
      sickness_visible, // eslint-disable-line
    } = this.state.currentProfile;

    const { email, firstName, lastName, haveDisease, disease } = this.state;
    const readyForApply = this.state.isReady
      && firstName !== ''
      && lastName !== ''
      && email !== ''
      && ((haveDisease && disease.trim() !== '') || !haveDisease);
    let missingFieldList = null;
    let missingAttachmentList = null;
    if (!readyForApply) {
      const titles = [];
      if (lastName === '') {
        const tabName = getKepviseloIds(this.props.match.params.id) ? 'Képviselő' : tab[this.state.currentProfile.tab];
        titles.push(`${tabName} vezetékneve:`);
      }
      if (firstName === '') {
        const tabName = getKepviseloIds(this.props.match.params.id) ? 'Képviselő' : tab[this.state.currentProfile.tab];
        titles.push(`${tabName} keresztneve:`);
      }
      if (email === '') {
        titles.push('E-mail cím:');
      }

      missingFieldList = this.renderMissingFields(_.concat(titles, this.state.missingFields));
      missingAttachmentList = this.renderMissingFields(this.state.missingAttachments);
    }

    return (
      <div className="page page-conference-apply">
        <h1>{name}</h1>

        <Collapse
          defaultActiveKey={this.state.activePanel}
          className="apply-panel"
        >
          <Panel header="Esemény információ" key="1">
            <div className="conference-summary">
              <div className="basic-information">
                <div className="summary-block">
                  <h2 className="block-title">
                    Az esemény leírása
                  </h2>
                  <div
                    className="block-content"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>

                <div className="summary-block">
                  <h2 className="block-title">
                    Egyéb tudnivalók
                  </h2>
                  <div
                    className="block-content"
                    dangerouslySetInnerHTML={{ __html: prices_dates }}
                  />
                </div>

                {/*
                  <div className="summary-block">
                    <h2 className="block-title">Program</h2>
                    <div
                      className="block-content"
                      dangerouslySetInnerHTML={{ __html: schedule }}
                    />
                  </div>
                */}
              </div>
            </div>
          </Panel>

          <Panel header="Személyes adatok" key="2">
            <div className="personal-info-form">
              <div className="form-row">
                <div className="form-column">
                  <label htmlFor="lastName">
                    {
                      this.props.match.params.id === '111' || this.props.match.params.id === '139'
                        ? 'Képviselő'
                        : tab[this.state.currentProfile.tab]
                    }
                    {' '}
vezetékneve:
                    <Input
                      id="lastName"
                      value={lastName}
                      onChange={(e) => {
                        this.setState({
                          lastName: e.target.value,
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="form-column">
                  <label htmlFor="firstName">
                    {
                      this.props.match.params.id === '111' || this.props.match.params.id === '139'
                        ? 'Képviselő'
                        : tab[this.state.currentProfile.tab]
                    }
                    {' '}
keresztneve:
                    <Input
                      id="firstName"
                      value={firstName}
                      onChange={(e) => {
                        this.setState({
                          firstName: e.target.value,
                        });
                      }}
                    />
                  </label>
                </div>

                <div className="form-column">
                  <label htmlFor="email">
                    E-mail:
                    <Input
                      id="email"
                      value={email}
                      onChange={(e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </Panel>

          {
            sickness_visible === '1' // eslint-disable-line
            && (
              <Panel header="Betegség" key="betegseg">
                <div className="personal-info-form">
                  <div className="form-row">
                    <div className="form-column">
                      <label htmlFor="haveDesease">
                        <div>Van-e valamilyen betegsége?</div>
                        <Switch
                          id="haveDesease"
                          checked={haveDisease}
                          onChange={(checked) => {
                            this.setState({
                              haveDisease: checked,
                            });
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  {
                    haveDisease
                    && (
                      <div className="form-row">
                        <div className="form-column">
                          <div>Betegség leírása</div>
                        </div>
                        <TextArea
                          value={disease}
                          onChange={(e) => {
                            this.setState({
                              disease: e.target.value,
                            });
                          }}
                        />
                      </div>
                    )
                  }
                </div>
              </Panel>
            )
          }

          <Panel header="Kérdőív" key="3">
            <ConfereceForm
              questions={this.state.questions}
              application={this.props.currentApplication}
              isNew={false}
              onChange={(values) => {
                this.checkAnswers(values);
              }}
            />
          </Panel>

          <Panel header="Mellékletek" key="4">
            <div className="attachment-fields">
              {this.state.attachments.map((field) => {
                const { id, title, isRequired, isUploaded, htmlContent } = field;
                const file = _.find(this.state.files, { field: `${id}` });
                return file !== undefined
                  ? this.renderUploadedButtons(id, title, isRequired, file.filename)
                  : this.renderIsUploaded(isUploaded, id, title, isRequired, isUploaded, htmlContent);
              })}
            </div>
          </Panel>
        </Collapse>

        {/* disabled={!readyForApply}
        description={`
          A jelentkezés véglegesítéséhez az alábbi mezők hiányoznak:
          ${this.state.missingFields.}
        `}
        */}


        {
          missingFieldList !== null && this.state.trySumbit
            ? (
              <Alert
                message="A regisztráció véglegesítéséhez az alábbi mezők kitöltése kötelező:"
                type="error"
                description={missingFieldList}
                style={{ marginBottom: '40px' }}
                showIcon
              />
            )
            : null
        }

        {
          missingAttachmentList !== null && this.state.trySumbit
            ? (
              <Alert
                message="A regisztráció véglegesítéséhez az alábbi csatolmányok hiányoznak:"
                type="error"
                description={missingAttachmentList}
                style={{ marginBottom: '40px' }}
                showIcon
              />
            )
            : null
        }

        <div className="conference-actions">
          <Button
            type="primary"
            className={`edit-button${!readyForApply ? ' semi-disabled' : ''}`}
            onClick={() => {
              if (!readyForApply) {
                this.setState({
                  trySumbit: true,
                });
                return;
              }

              const field = {
                conference: {
                  id: this.state.currentProfile.id,
                },
                application: {
                  id: this.props.match.params.applicationId,
                },
                user: {
                  email: this.state.email,
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                },
                disease: {
                  haveDisease: this.state.haveDisease ? 1 : 0,
                  disease: this.state.haveDisease ? this.state.disease : null,
                },
                answers: [],
              };

              Object.keys(this.state.answers).forEach((id) => {
                if (Object.prototype.hasOwnProperty.call(this.state.answers, id)) {
                  field.answers.push({
                    id,
                    answer: this.state.answers[id].answer || '',
                  });
                }
              });

              this.props.dispatchUpdateApplication(
                field.conference,
                field.application,
                field.user,
                field.disease,
                field.answers,
                true,
              );
            }}
          >
            Jelentkezés módosítása
          </Button>

          {/*
            <Button
              type="danger"
              key="cancel"
              className="cancel-button"
              onClick={this.handleCancelApply}
            >
              Jelentkezés törlése
            </Button>
          */}
        </div>
      </div>
    );
  }
}

ConferenceAdminApply.defaultProps = {
  user: {
    admin: false,
  },
  conferences: null,
  currentProfile: null,
  currentApplication: {
    user: {
      email: '',
      firstName: '',
      lastName: '',
    },
    files: [],
  },
};

ConferenceAdminApply.propTypes = {
  dispatchGetConferences: PropTypes.func.isRequired,
  dispatchGetConference: PropTypes.func.isRequired,
  dispatchGetApplication: PropTypes.func.isRequired,
  dispatchCancelApplication: PropTypes.func.isRequired,
  dispatchUpdateApplication: PropTypes.func.isRequired,
  conferences: PropTypes.arrayOf(PropTypes.shape()),
  currentProfile: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentApplication: PropTypes.shape({
    user: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      have_disease: PropTypes.string,
      disease: PropTypes.string,
    }),
    files: PropTypes.arrayOf(PropTypes.shape()),
  }),
  user: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      applicationId: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = store => ({
  conferences: store.conferences.conferences,
  currentProfile: store.conferences.currentProfile,
  currentApplication: store.conferences.currentApplication,
});

const mapDispatchToProps = {
  dispatchGetConferences: getConferences,
  dispatchGetConference: getConference,
  dispatchApplyForConference: applyForConference,
  dispatchUpdateApplication: updateApplication,
  dispatchCancelApplication: cancelApplication,
  dispatchGetApplication: getApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceAdminApply);
