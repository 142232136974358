import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { ReactComponent as BarkaBg } from 'static/barka-bg.svg';
import { ReactComponent as BarkaLogo } from 'static/barka-logo.svg';
import { ReactComponent as BarkaLogoCircle } from 'static/barka-logo-circle.svg';
import { ReactComponent as RkkLogo } from 'static/rkk-logo.svg';
import SzechenyiLogo from 'static/szechenyi-logo.png';
import './layout-login.scss';

const { Header, Content, Footer } = Layout;


const LoginLayout = ({ children }) => {
  const svgString = encodeURIComponent(renderToStaticMarkup(<BarkaBg />));
  const dataUri = `url("data:image/svg+xml,${svgString}")`;

  return (
    <div
      className="layout layout-login"
      style={{
        background: dataUri,
        backgroundPositionX: 'center',
        backgroundRepeat: 'repeat-y',
      }}
    >
      <Layout className="layout">
        <Header>
          <div className="app-menu">
            <div className="left-panel">
              <div className="logo">
                <BarkaLogo />
              </div>
            </div>
            <div className="right-panel" />
          </div>
        </Header>
        <Content>
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <div className="footer-wrap">
            <div className="left-panel">
              <RkkLogo />
            </div>
            <div className="center-panel">
              <span className="owner-rights">
                {`Bárka Tábor, Minden jog fenntartva ©${new Date().getFullYear()}`}
              </span>
              <BarkaLogoCircle style={{ minWidth: '59px' }} />
            </div>
            <div className="right-panel">
              <img src={SzechenyiLogo} alt="Széchenyi terv logo" />
            </div>
          </div>
        </Footer>
      </Layout>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.element,
};

LoginLayout.defaultProps = {
  children: null,
};

export default withTranslation()(LoginLayout);
