import {
  USER_LOGIN_REQUESTED,
  USER_LOGIN_SUCCEEDED,
  USER_LOGIN_FAILED,
  RESTORE_USER_BY_TOKEN_SUCCEEDED,
  USER_LOGOUT_SUCCEEDED,
} from 'consts/actionTypes';
import initialState from './initialState';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUESTED: {
      return {
        ...state,
        isAuthenticating: true,
        email: action.payload.email,
      };
    }

    case USER_LOGIN_SUCCEEDED: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isAuthenticatingError: false,
        authenticatingErrorMessage: '',
        access_token: action.token.access_token,
        admin: Boolean(Number(action.credentials.data.superuser)),
      };
    }

    case USER_LOGIN_FAILED: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticatingError: true,
        authenticatingErrorMessage: action.message,
      };
    }

    case RESTORE_USER_BY_TOKEN_SUCCEEDED: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        access_token: localStorage.getItem('token'),
        email: action.credentials.data.email,
        admin: Boolean(Number(action.credentials.data.superuser)),
      };
    }

    case USER_LOGOUT_SUCCEEDED: {
      return {
        ...state,
        isAuthenticated: false,
        access_token: null,
        username: undefined,
        uid: undefined,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
