import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { history } from 'store/store';
import Spinner from 'react-spinkit';
import { Card, Tabs } from 'antd';
import ConferenceBoard from 'components/ConferenceBoard/ConferenceBoard';
import { getConferences } from 'actions/appActions';
import './dashboard.scss';

const { TabPane } = Tabs;


export class Dashboard extends React.Component {
  componentWillMount() {
    this.props.getConferences(['isPreReg=0']);
  }

  componentDidMount() {
    document.getElementsByClassName('ant-layout-content')[0].scrollTop = 0;
  }

  handleActionButtonClick = (id, action) => {
    switch (action) {
      case 'apply': {
        history.push(`/conferences/${id}`);
        break;
      }

      default:
    }
  }

  render() {
    return (
      <div className="page page-dashboard">
        <div className="page-header">
          <div className="page-header-wrap">
            {`TÁBOR TURNUSOK ${new Date().getFullYear()}`}
          </div>
        </div>

        {!this.props.conferences
          ? (
            <div className="loader-container">
              <Spinner name="ball-beat" color="#23b7e5" />
              <div className="loader-title">A turnusok betöltése folyamatban van...</div>
            </div>
          )
          : (
            <div className="conferences-tabs">
              <Tabs defaultActiveKey="0" style={{ marginTop: 20 }}>
                <TabPane tab="Információk" key="0">
                  <div className="conferences-list-info">
                    <Card title="Köszöntjük!" bordered>
                      <p>
                        A jelentkezés kategóriája szerint kérjük kattintson a kapcsolódó fülre (Előregisztráció, Tanulók, Kísérők),
                         válasszon egy eseményt, kattintson az adatlap gombra és kövesse a megjelenő információkat.
                      </p>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tab="Előregisztráció" key="1">
                  <ConferenceBoard
                    conferences={this.props.conferences}
                    tab="3"
                    actionButtons={[
                      { label: 'Adatlap', action: 'apply' },
                    ]}
                    onClick={this.handleActionButtonClick}
                  />
                </TabPane>
                <TabPane tab="Tanulók" key="2">
                  <ConferenceBoard
                    conferences={this.props.conferences}
                    tab="1"
                    actionButtons={[
                      { label: 'Adatlap', action: 'apply' },
                    ]}
                    onClick={this.handleActionButtonClick}
                  />
                </TabPane>
                <TabPane tab="Kísérők" key="3">
                  <ConferenceBoard
                    conferences={this.props.conferences}
                    tab="2"
                    actionButtons={[
                      { label: 'Adatlap', action: 'apply' },
                    ]}
                    onClick={this.handleActionButtonClick}
                  />
                </TabPane>
              </Tabs>
            </div>
          )
        }
      </div>
    );
  }
}

Dashboard.defaultProps = {
  conferences: null,
};

Dashboard.propTypes = {
  getConferences: PropTypes.func.isRequired,
  conferences: PropTypes.arrayOf(PropTypes.shape()),
};

const mapStateToProps = store => ({
  conferences: store.conferences.conferences,
});

const mapDispatchToProps = {
  getConferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
