import React from 'react';
import { Link } from 'react-router-dom';


const Page404 = () => (
  <div className="page page-404">
    <header className="app-header">
      <p>404</p>
      <p>
        <Link to="/">Back to /</Link>
      </p>
    </header>
  </div>
);

export default Page404;
