import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Select, Input, Modal, Button, Icon, Switch } from 'antd';
import QuestionBlockHeader from './QuestionBlockHeader';
import './questionblock.scss';

const { Option } = Select;


class QuestionBlock extends React.Component {
  constructor(props) {
    super(props);

    const options = [];
    this.props.question.options.forEach((option) => {
      options.push({
        id: parseInt(option.id, 10),
        value: option.value,
        isSaved: true,
      });
    });

    this.state = {
      id: this.props.question.id,
      title: this.props.question.title,
      type: this.props.question.type,
      isRequired: this.props.question.isRequired,
      options,
      optionInput: '',
      deleteOptionsModalIsVisible: false,
    };

    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleOptionInputChange = this.handleOptionInputChange.bind(this);
    this.handleOptionValueChange = this.handleOptionValueChange.bind(this);
    this.handleAddOption = this.handleAddOption.bind(this);
    this.handleRemoveOption = this.handleRemoveOption.bind(this);
    this.handleDeleteModalCancel = this.handleDeleteModalCancel.bind(this);
    this.handleDeleteModalContinue = this.handleDeleteModalContinue.bind(this);
    this.handleRequiredChange = this.handleRequiredChange.bind(this);
    this.renderQuestionTypeBody = this.renderQuestionTypeBody.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
  }

  handleTitleChange(e) {
    this.setState({
      title: e.target.value,
    }, () => {
      this.updateQuestion();
    });
  }

  handleDeleteModalCancel() {
    this.setState({
      deleteOptionsModalIsVisible: false,
    });
  }

  handleDeleteModalContinue() {
    this.setState({
      type: 'text',
      options: [],
      optionInput: '',
      deleteOptionsModalIsVisible: false,
    }, () => {
      this.updateQuestion();
    });
  }

  handleRequiredChange(isRequired) {
    this.setState({
      isRequired,
    }, () => {
      this.updateQuestion();
    });
  }

  handleTypeChange(type) {
    switch (type) {
      case 'checkbox':
      case 'dropdown': {
        this.setState({
          type,
        }, () => this.updateQuestion());
        break;
      }

      case 'date':
      case 'text':
      case 'textarea': {
        if (this.state.options.length !== 0) {
          this.setState({
            deleteOptionsModalIsVisible: true,
          });
        } else {
          this.setState({
            type,
          }, () => this.updateQuestion());
        }
        break;
      }

      default:
    }
  }

  handleOptionInputChange(e) {
    this.setState({
      optionInput: e.target.value,
    }, () => this.updateQuestion());
  }

  handleOptionValueChange(e) {
    const { options } = this.state;
    const { target } = e;

    const item = _.find(options, { id: parseInt(e.target.dataset.id, 10) });
    item.value = target.value;

    this.setState({
      options,
    }, () => this.updateQuestion());
  }

  handleAddOption(e) {
    if (e.target.value === '') {
      return;
    }

    const { options } = this.state;

    options.push({
      id: new Date().getTime(),
      value: e.target.value,
      isSaved: false,
    });

    this.setState({
      options,
      optionInput: '',
    }, () => {
      this.updateQuestion();
    });
  }

  handleRemoveOption(id) {
    const { options } = this.state;
    const removedItems = _.remove(options, { id });
    this.setState({
      options,
    }, () => {
      this.updateQuestion();
      if (removedItems[0].isSaved) {
        this.props.deleteOption(removedItems[0].id);
      }
    });
  }

  updateQuestion() {
    this.props.updateQuestion(
      this.state.id,
      {
        id: this.state.id,
        title: this.state.title,
        type: this.state.type,
        options: this.state.options,
        isRequired: this.state.isRequired,
      },
    );
  }

  renderQuestionTypeBody(type) {
    switch (type) {
      case 'date':
      case 'text':
      case 'textarea': {
        return (
          <div className="no-more-options">
            Ehhez a kérdéstípushoz nem tartozik további beállítás.
          </div>
        );
      }

      case 'checkbox':
      case 'dropdown': {
        return (
          <div className="options-body">
            Lehetséges válaszok
            <div className="option-values">
              {this.state.options.map(option => (
                <div
                  key={option.id}
                  className="option-value"
                >
                  <Input
                    className="option-value-filed"
                    value={option.value}
                    data-id={option.id}
                    onChange={this.handleOptionValueChange}
                  />
                  <Button
                    onClick={() => this.handleRemoveOption(option.id)}
                  >
                    <Icon
                      type="close"
                    />
                  </Button>
                </div>
              ))}
            </div>

            <div className="option-input">
              <Input
                value={this.state.optionInput}
                prefix={<Icon type="plus" />}
                placeholder={this.state.options.length === 0
                  ? 'Adja meg az első opciót'
                  : 'Opció hozzáadása'
                }
                onChange={this.handleOptionInputChange}
                onPressEnter={this.handleAddOption}
              />
            </div>
          </div>
        );
      }

      default: return null;
    }
  }

  render() {
    const { id, title, type } = this.state;

    return (
      <div className="question-block">
        <Modal
          title="Megerősítés szükséges"
          visible={this.state.deleteOptionsModalIsVisible}
          okText="Folytatás"
          cancelText="Mégsem"
          onOk={this.handleDeleteModalContinue}
          onCancel={this.handleDeleteModalCancel}
        >
          <p>A jelenlegi válaszlehetőségek elvesznek, ha megváltoztatja ennek a kérdésnek a típusát szöveges mezőre</p>
          <p><strong>Folytatja?</strong></p>
        </Modal>


        <QuestionBlockHeader
          id={id}
          title={title}
          questionId={this.props.question.id}
          isFirst={this.props.isFirst}
          isLast={this.props.isLast}
          handleTitleChange={this.handleTitleChange}
          moveQuestion={this.props.moveQuestion}
          deleteQuestion={this.props.deleteQuestion}
        />

        <div className="block-content">
          <div className="block-options">
            {this.renderQuestionTypeBody(type)}
          </div>

          <div className="block-type">
            <Select
              value={type}
              className="type-selector"
              onChange={this.handleTypeChange}
            >
              <Option value="text">Rövid válasz</Option>
              <Option value="textarea">Hosszú válasz</Option>
              <Option value="checkbox">Jelölőnégyzet</Option>
              <Option value="dropdown">Legördülő lista</Option>
              <Option value="date">Dátum</Option>
            </Select>

            <div className="is-required">
              <button
                type="button"
                className="switch-button"
                onClick={() => {
                  this.handleRequiredChange(!this.props.question.isRequired);
                }}
              >
                <span className="switch-label">Kötelező mező?</span>
                <Switch
                  checked={this.props.question.isRequired}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="cross" />}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuestionBlock.propTypes = {
  updateQuestion: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isSaved: PropTypes.bool,
    isRequired: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default QuestionBlock;
