import {
  APP_CHANGE_LANGUAGE,
} from 'consts/actionTypes';
import initialState from './initialState';


const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.payload.lang,
      };

    default:
      return state;
  }
};

// export initialState;
export default userReducer;
