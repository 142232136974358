import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Dropdown, Icon, Layout, Menu } from 'antd';
import { ReactComponent as BarkaBg } from 'static/barka-bg.svg';
import { ReactComponent as BarkaLogo } from 'static/barka-logo.svg';
import { history } from 'store/store';
import { userLogout } from 'actions/appActions';
import './layout-app.scss';

const { Header, Content, Footer } = Layout;
const appMenu = [
  { link: '/turnusok', label: 'TURNUSOK', isAdminRoute: false },
  // { link: '/conferences/111', label: 'ELŐREGISZTRÁCIÓ', isAdminRoute: false },
  { link: '/admin/conferences', label: 'ADMIN', isAdminRoute: true },
];

const renderAppMenu = userIsAdmin => (
  appMenu.map((menu) => {
    const routeItem = (
      <Link
        key={menu.link}
        className="app-menu-item"
        to={menu.link}
      >
        {menu.label}
      </Link>
    );

    if (menu.isAdminRoute) {
      return userIsAdmin ? routeItem : null;
    }

    return routeItem;
  })
);

class AppLayout extends React.Component {
  handleUserMenuClick = (e) => {
    switch (e.key) {
      /*
        case '1': {
          handleLoadProfilePage();
          break;
        }
      */

      case '2': {
        history.push('/profile/change-password');
        break;
      }

      case '3': {
        this.props.dispatchUserLogout();
        break;
      }

      default:
    }
  }

  render() {
    const svgString = encodeURIComponent(renderToStaticMarkup(<BarkaBg />));
    const dataUri = `url("data:image/svg+xml,${svgString}")`;
    const menu = (
      <Menu onClick={this.handleUserMenuClick}>
        {/*
          <Menu.Item key="1">{i18next.t('usermenu.my_profile')}</Menu.Item>
          <Menu.Divider />
        */}
        <Menu.Item key="2">{i18next.t('usermenu.change_password')}</Menu.Item>
        <Menu.Item key="3">{i18next.t('usermenu.logout')}</Menu.Item>
      </Menu>
    );

    return (
      <div
        className="layout layout-app"
        style={{
          background: dataUri,
          backgroundPositionX: 'center',
          backgroundRepeat: 'repeat-y',
        }}
      >
        <Layout className="layout">
          <Header>
            <div className="app-menu">
              <div className="left-panel">
                <div className="logo">
                  <BarkaLogo />
                </div>
                <div className="header-app-menu">
                  {this.props.user.isAuthenticated
                    ? renderAppMenu(this.props.user.admin)
                    : null
                  }
                </div>
              </div>
              <div className="right-panel">
                <Dropdown overlay={menu}>
                  <Button style={{ marginLeft: 8 }}>
                    <span>{this.props.user.email}</span>
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content>
            {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <span className="owner-rights">
              {`Bárka Tábor, Minden jog fenntartva ©${new Date().getFullYear()}`}
            </span>
          </Footer>
        </Layout>
      </div>
    );
  }
}

AppLayout.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    admin: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
  }).isRequired,
  children: PropTypes.element,
  dispatchUserLogout: PropTypes.func.isRequired,
};

AppLayout.defaultProps = {
  children: null,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = {
  dispatchUserLogout: userLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppLayout));
