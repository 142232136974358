import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Checkbox, DatePicker, Select, Input } from 'antd';
import * as datePickerLocales from 'locales/datePickerLocales';

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { TextArea } = Input;


class ConferenceForm extends React.Component {
  constructor(props) {
    super(props);

    const answers = {};
    this.props.questions.forEach((question) => {
      answers[question.id] = {
        required: Boolean(Number(question.required)),
        answer: null,
      };

      if (!this.props.isNew) {
        const applicationAnswer = _.find(this.props.application.application_data, { question_id: question.id });
        answers[question.id].answer = applicationAnswer.answer || null;
      }
    });

    this.state = {
      answers,
    };

    this.renderQuestionContent = this.renderQuestionContent.bind(this);
  }

  componentDidMount() {
    this.props.onChange(this.state.answers);
  }

  renderQuestionContent(id, type, options) {
    switch (type) {
      case 'checkbox': {
        const optionsList = [];
        options.forEach((option) => {
          optionsList.push({
            value: option.id,
            label: option.value,
          });
        });

        return (
          <CheckboxGroup
            options={optionsList}
            defaultValue={this.state.answers[id].answer || []}
            onChange={(value) => {
              const { answers } = this.state;
              answers[id].answer = value;

              this.setState({
                answers,
              }, () => {
                this.props.onChange(this.state.answers);
              });
            }}
          />
        );
      }

      case 'date': {
        const dateValue = this.state.answers[id].answer === null
          ? null
          : moment(this.state.answers[id].answer);

        return (
          <DatePicker
            format="YYYY.MM.DD"
            locale={datePickerLocales.default}
            defaultValue={dateValue}
            size="large"
            onChange={(date) => {
              const { answers } = this.state;
              answers[id].answer = date === null ? null : moment(date).format('YYYY-MM-DD HH:mm:ss');

              this.setState({
                answers,
              }, () => {
                this.props.onChange(this.state.answers);
              });
            }}
          />
        );
      }

      case 'dropdown': {
        const children = [];
        options.forEach((option) => {
          children.push(<Option key={option.id}>{option.value}</Option>);
        });

        return (
          <Select
            placeholder="Válasszon egy lehetőséget"
            style={{ minWidth: 200 }}
            dropdownClassName="select-dropdown-options"
            defaultValue={this.state.answers[id].answer || ''}
            onChange={(value) => {
              const { answers } = this.state;
              answers[id].answer = value;

              this.setState({
                answers,
              }, () => {
                this.props.onChange(this.state.answers);
              });
            }}
          >
            {children}
          </Select>
        );
      }

      case 'text': {
        return (
          <Input
            defaultValue={this.state.answers[id].answer || ''}
            onChange={(e) => {
              const { answers } = this.state;
              answers[id].answer = e.target.value;

              this.setState({
                answers,
              }, () => {
                this.props.onChange(this.state.answers);
              });
            }}
          />
        );
      }

      case 'textarea': {
        return (
          <TextArea
            defaultValue={this.state.answers[id].answer || ''}
            onChange={(e) => {
              const { answers } = this.state;
              answers[id].answer = e.target.value;

              this.setState({
                answers,
              }, () => {
                this.props.onChange(this.state.answers);
              });
            }}
          />
        );
      }

      default: return `type: ${type}`;
    }
  }

  render() {
    const { questions } = this.props;

    return (
      <div className="form-questions">
        {questions.map(question => (
          <div key={question.id} className="question-block">
            <h2 className="question-title">
              <div>
                {question.title}
                {Number(question.required)
                  ? <span className="question-required-times">*</span>
                  : null
                }
              </div>
            </h2>
            <div className="question-content">
              {
                this.renderQuestionContent(question.id, question.type, question.options)
              }
            </div>
          </div>
        ))}
      </div>
    );
  }
}

ConferenceForm.defaultProps = {
  isNew: true,
  application: {
    application_data: [],
  },
};

ConferenceForm.propTypes = {
  isNew: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  application: PropTypes.shape({
    application_data: PropTypes.arrayOf(PropTypes.shape()),
  }),
  onChange: PropTypes.func.isRequired,
};

export default ConferenceForm;
