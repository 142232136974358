import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'components/Root';
import configureStore, { history } from 'store/store';
import './utils/fontawesome-icons';
import './locales/i18n';
import 'styles/ant-variables.less';
// import 'styles/font-awesome.css';
import $ from 'jquery';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/languages/hu.js';
import 'styles/index.scss';
import * as serviceWorker from './serviceWorker';

window.$ = $;
const store = configureStore();

ReactDOM.render(<Root store={store} history={history} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
