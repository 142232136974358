import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faTrashAlt,
  faEdit,
  faAddressCard,
  faCircle,
  faStar as farStar,
  faHeart as farHeart,
} from '@fortawesome/free-regular-svg-icons';
import {
  faFingerprint,
  faEllipsisV,
  faIdCardAlt,
  faEnvelope,
  faPhone,
  faExpand,
  faUser,
  faDotCircle,
  faBuilding,
  faMapMarkerAlt,
  faAsterisk,
  faSlash,
  faLink,
  faStar as fasStar,
  faHeart as fasHeart,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTimesCircle,
  farStar, fasStar,
  farHeart, fasHeart,
  faFingerprint,
  faEllipsisV,
  faIdCardAlt,
  faEnvelope,
  faPhone,
  faExpand,
  faUser,
  faBuilding,
  faEdit,
  faAddressCard,
  faTrashAlt,
  faCircle,
  faDotCircle,
  faMapMarkerAlt,
  faAsterisk,
  faSlash,
  faLink,
);
