import React from 'react';
import PropTypes from 'prop-types';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';


/*
That's where the magic happens
const FroalaEditor = dynamic(import('react-froala-wysiwyg'), {
  ssr: false
});
*/

import FroalaEditor from 'react-froala-wysiwyg';

const toolbarButtonsList = [
  'bold', 'italic', 'underline', 'fontSize', 'color', '|',
  'strikeThrough', 'subscript', 'superscript', 'outdent', 'indent', 'formatOL', 'formatUL', 'insertLink', '|',
  'undo', 'redo', 'clearFormatting', 'html',
];

const pluginsEnabledList = [
  'align', 'codeBeautifier', 'codeView', 'colors', 'entities', 'fontSize',
  'fullscreen', 'lineBreaker', 'link', 'insertLink', 'paragraphFormat', 'paragraphStyle', 'quote', 'url',
  'wordPaste',
];

export const FroalaEditorBox = ({ field, model, handleChange }) => (
  <FroalaEditor
    tag="textarea"
    model={model}
    config={{
      heightMin: 120,
      language: 'hu',
      key: 'wD7B6D6F5hB3D3B11D9C3B4A3F3D3H3crddlvhgsH-7zD8cy==',
      pluginsEnabled: pluginsEnabledList,
      toolbarButtons: toolbarButtonsList,
      toolbarButtonsMD: toolbarButtonsList,
      toolbarButtonsSM: toolbarButtonsList,
      toolbarButtonsXS: toolbarButtonsList,
    }}
    style={{
      marginTop: '20px !important',
    }}
    onModelChange={(modelValue) => {
      handleChange(field, modelValue);
    }}
  />
);

FroalaEditorBox.propTypes = {
  handleChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
};

export default FroalaEditorBox;
