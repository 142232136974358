import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Button, Table, Tabs } from 'antd';
import moment from 'moment';
import { history } from 'store/store';
import {
  getConferences,
  getConference,
  getApplications,
  createApplicationSlot,
} from 'actions/appActions';
import { getKepviseloIds } from 'utils/utils';
import './conferenceprofile.scss';

const { TabPane } = Tabs;
const tabs = {
  1: 'Tanuló',
  2: 'Kísérő',
  3: 'Képviselő',
};

class ConferenceProfile extends React.Component {
  constructor(props) {
    super(props);

    this.props.dispatchGetApplications(this.props.match.params.id);

    let conference;
    let currentProfile = null;

    if (this.props.conferences === null) {
      this.props.dispatchGetConferences();
    } else {
      conference = _.find(this.props.conferences, { id: this.props.match.params.id });

      if (conference === undefined) {
        this.props.dispatchGetConferences(['isPreReg=1']);
      }
    }

    if (this.props.currentProfile !== null) {
      if (this.props.currentProfile.id !== this.props.match.params.id) {
        this.props.dispatchGetConference(this.props.match.params.id);
      } else {
        ({ currentProfile } = this.props);
      }
    } else {
      this.props.dispatchGetConference(this.props.match.params.id);
    }

    const { tab } = queryString.parse(this.props.location.search);

    this.state = {
      currentProfile,
      currentApplications: this.props.currentApplications,
      activeKey: tab || 'informations',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentProfile !== this.state.currentProfile) {
      this.setState({
        currentProfile: nextProps.currentProfile,
      });
    }

    if (nextProps.currentApplications !== this.state.currentApplications) {
      this.setState({
        currentApplications: nextProps.currentApplications,
      });
    }
  }

  render() {
    if (this.state.currentProfile === null) return null;

    const {
      name,
      description,
      prices_dates, // eslint-disable-line
      preregdata,
    } = this.state.currentProfile;

    const tabName = getKepviseloIds(this.props.match.params.id)
      ? 'Képviselő'
      : tabs[this.state.currentProfile.tab];

    const columns = [
      {
        title: `${tabName} vezetékneve`,
        dataIndex: 'vezeteknev',
        key: 'vezeteknev',
      },
      {
        title: `${tabName} keresztneve`,
        dataIndex: 'keresztnev',
        key: 'keresztnev',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Jelentkezés ideje',
        dataIndex: 'date',
        key: 'date',
        render: (text, row) => (
          <span key="date">
            {moment(row.date).format('YYYY.MM.DD HH:mm:ss')}
          </span>
        ),
      },
      this.state.currentProfile.id === '111'
        ? {}
        : {
          title: 'Műveletek',
          key: 'action',
          render: (text, row) => (
            [
              <span key="edit">
                <Button
                  onClick={() => {
                    history.push(`/conferences/${this.props.match.params.id}/applications/${row.id}`);
                  }}
                >
                  Szerkesztés
                </Button>
              </span>,
            ]
          ),
        },
    ];

    return (
      <div className="page page-conference-profile">
        <div className="page-header">
          <div className="page-header-wrap">TÁBOR adatlap</div>
        </div>

        <div className="page-content">
          <div className="page-content-wrap">

            <h1 className="page-conference-profile-title">{name}</h1>
            <Link to="/turnusok">
              <Button icon="left">Vissza</Button>
            </Link>

            {/*
              <Alert
                message="Esemény adatlap"
                description={
                  `Az alábbiakban hasznos információkat talál. Jelentkezéshez kérjük,
                  görgessen az oldal aljára és kattintson a Jelentkezés gombra.
                `}
                type="info"
                showIcon
              />
            */}

            <Tabs
              activeKey={this.state.activeKey}
              onChange={(activeKey) => {
                this.setState({
                  activeKey,
                });
              }}
            >
              <TabPane tab="Információk az eseményről" key="informations">
                <div className="conference-summary">
                  <div className="prereg-info-header">
                    <div className="block-title">
                      Aktuális előjelentkezések száma turnusonként
                    </div>
                    <div>(tájékoztató adatok)</div>
                  </div>
                  <div className="prereg-info-cards">
                    {
                      preregdata
                      && preregdata.map(item => (
                        <div key={item.turnus_id} className="prereg-info-card-container">
                          <div className="prereg-info-card">
                            <div className="title">{item.title}</div>
                            <div className="body">{item.value || 0}</div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div className="basic-information">
                    <div className="summary-block">
                      <h2 className="block-title">
                        Az esemény leírása
                      </h2>
                      <div
                        className="block-content"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </div>

                    <div className="summary-block">
                      <h2 className="block-title">
                        Egyéb tudnivalók
                      </h2>
                      <div
                        className="block-content"
                        dangerouslySetInnerHTML={{ __html: prices_dates }}
                      />
                    </div>

                    {/*
                      <div className="summary-block">
                        <h2 className="block-title">Program</h2>
                        <div
                          className="block-content"
                          dangerouslySetInnerHTML={{ __html: schedule }}
                        />
                      </div>
                    */}
                  </div>
                </div>

                {/*
                  <div className="conference-actions">
                    {Number(haveApplication)
                      ? [
                        <Alert
                          key="alert"
                          message="Sikeres jelentkezés"
                          description="Erre az eseményre korábban már jelentkezett."
                          type="success"
                          showIcon
                        />,
                        <Button
                          key="edit"
                          onClick={() => {
                            history.push(`/conferences/${this.state.currentProfile.id}/apply`);
                          }}
                        >
                          Jelentkezés módosítása
                        </Button>,
                        <Button
                          type="danger"
                          key="cancel"
                          onClick={this.handleCancelApply}
                        >
                          Jelentkezés törlése
                        </Button>,
                      ]
                      : <Button
                        type="primary"
                        onClick={() => {
                          history.push(`/conferences/${this.state.currentProfile.id}/apply`);
                        }}
                      >
                        Jelentkezés
                      </Button>
                    }
                  </div>
                */}
                <div className="conference-actions">
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.dispatchCreateApplicationSlot({
                        id: this.state.currentProfile.id,
                      });
                    }}
                  >
                    Új jelentkezés rögzítése
                  </Button>
                </div>
              </TabPane>

              <TabPane tab="Az eseményre jelentkezettek listája" key="applications">
                {this.state.currentApplications === null
                  ? null
                  : (
                    <Table
                      dataSource={this.state.currentApplications}
                      columns={columns}
                      pagination={false}
                    />
                  )
                }
                <div className="conference-actions">
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.dispatchCreateApplicationSlot({
                        id: this.state.currentProfile.id,
                      });
                    }}
                  >
                    Új jelentkezés rögzítése
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

ConferenceProfile.defaultProps = {
  conferences: null,
  currentProfile: null,
  currentApplications: null,
  location: {
    query: {
      tab: 'informations',
    },
  },
};

ConferenceProfile.propTypes = {
  dispatchCreateApplicationSlot: PropTypes.func.isRequired,
  dispatchGetConferences: PropTypes.func.isRequired,
  dispatchGetConference: PropTypes.func.isRequired,
  dispatchGetApplications: PropTypes.func.isRequired,
  conferences: PropTypes.arrayOf(PropTypes.shape()),
  currentProfile: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentApplications: PropTypes.arrayOf(PropTypes.shape()),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.shape(),
    query: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),
};

const mapStateToProps = store => ({
  conferences: store.conferences.conferences,
  currentProfile: store.conferences.currentProfile,
  currentApplication: store.conferences.currentApplication,
  currentApplications: store.conferences.currentApplications,
});

const mapDispatchToProps = {
  dispatchGetConferences: getConferences,
  dispatchGetConference: getConference,
  dispatchGetApplications: getApplications,
  dispatchCreateApplicationSlot: createApplicationSlot,
};


export default connect(mapStateToProps, mapDispatchToProps)(ConferenceProfile);
