export default {
  translation: {
    pages: {
      login: {
        email: 'E-mail cím',
        password: 'Jelszó',
      },
      dashboard: {
        hello_world: 'Hello World!',
      },
    },
  },
};
