import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import swal from 'sweetalert';
import { Alert, Button, Form, Input } from 'antd';
import * as appActions from 'actions/appActions';

const FormItem = Form.Item;


class ChangePassword extends React.Component {
  getFieldDecorator = this.props.form.getFieldDecorator;

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.newPassword !== values.rePassword) {
          swal({
            title: 'Hiba!',
            text: 'A jelszavak nem egyeznek.',
            icon: 'error',
            button: {
              text: 'Javítom',
            },
          });

          return;
        }

        this.props.changePassword(values.currentPassword, values.newPassword);
      }
    });
  }

  render() {
    return (
      <div className="page">
        <div className="page-header">
          <div className="page-header-wrap">Jelszó módosítása</div>
        </div>

        <div className="page-content">
          <div className="page-content-wrap">
            <Alert
              message="Jelszó módosítása"
              description={
                `Jelszavának módosításához kérjük üsse be jelenlegi jelszavát,\n
                majd adja meg új jelszavát és erősíte is meg azt.`
              }
              type="info"
              className="password-reset-alert"
              showIcon
            />

            <Form onSubmit={this.handleSubmit} className="password-reset-form">
              <FormItem label={i18next.t('pages.profile.changePassword.current_password')}>
                {this.getFieldDecorator('currentPassword', {
                  rules: [{
                    required: true,
                  }],
                })(
                  <Input type="password" />,
                )}
              </FormItem>

              <FormItem label={i18next.t('pages.profile.changePassword.new_password')}>
                {this.getFieldDecorator('newPassword', {
                  rules: [{
                    required: true,
                  }],
                })(
                  <Input type="password" />,
                )}
              </FormItem>

              <FormItem label={i18next.t('pages.profile.changePassword.retype_password')}>
                {this.getFieldDecorator('rePassword', {
                  rules: [{
                    required: true,
                  }],
                })(
                  <Input type="password" />,
                )}
              </FormItem>

              <FormItem className="button-wrap">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ width: '100%' }}
                >
                  {i18next.t('pages.profile.changePassword.change_password')}
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  changePassword: appActions.changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ChangePassword));
