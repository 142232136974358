import _ from 'lodash';
import initialState from './initialState';

function newConferenceReducer(state = initialState, action) {
  switch (action.type) {
    case 'NEWCONFERENCE_NEXT_STEP': {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case 'NEWCONFERENCE_PREV_STEP': {
      return {
        ...state,
        currentStep: Math.max(state.currentStep - 1, 0),
      };
    }

    case 'NEWCONFERENCE_CREATE_REQUESTED':
    case 'NEWCONFERENCE_UPDATE_REQUESTED': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'NEWCONFERENCE_CREATED': {
      return {
        ...state,
        id: action.id,
        isLoading: false,
        currentStep: 1,
      };
    }

    case 'NEWCONFERENCE_UPDATED': {
      return {
        ...state,
        isLoading: false,
        currentStep: 1,
      };
    }

    case 'NEWCONFERENCE_CHANGE_ATTRIBUTE': {
      return {
        ...state,
        [action.attribute]: action.payload,
      };
    }

    case 'NEWCONFERENCE_ADD_QUESTION': {
      return {
        ...state,
        questions: state.questions.concat(action.question),
      };
    }

    case 'NEWCONFERENCE_UPDATE_QUESTION': {
      const questions = state.questions.map((q) => {
        if (q.id === action.id) {
          const { title, type, isRequired } = action.question;
          return {
            ...q,
            title,
            type,
            isRequired,
            options: action.question.options.map(o => o),
          };
        }

        return q;
      });
      // const index = _.findIndex(questions, { id: action.id });
      // questions.splice(index, 1, action.question);

      return {
        ...state,
        questions,
      };
    }

    case 'NEWCONFERENCE_DELETE_QUESTION_SUCCEDED': {
      const { questions } = state;
      _.remove(questions, { id: action.id });

      return {
        ...state,
        questions,
      };
    }

    case 'NEWCONFERENCE_MOVE_QUESTION': {
      /*
      ** DIRECTIONS:
      ** -----------
      ** UP   : -1
      ** DOWN : +1
      */

      const questions = state.questions.map(q => q);
      const index = _.findIndex(questions, { id: action.id });
      const items = _.remove(questions, { id: action.id });
      questions.splice(index + action.direction, 0, items[0]);

      return {
        ...state,
        questions,
      };
    }

    case 'NEWCONFERENCE_ADD_ATTACHMENT': {
      const attachments = state.attachments.map(a => a).concat(action.attachment);

      return {
        ...state,
        attachments,
      };
    }

    case 'NEWCONFERENCE_UPDATE_ATTACHMENT': {
      const attachments = state.attachments.map(a => a);
      const index = _.findIndex(attachments, { id: action.id });
      attachments.splice(index, 1, action.attachment);

      return {
        ...state,
        attachments,
      };
    }

    case 'NEWCONFERENCE_DELETE_ATTACHMENT_SUCCEDED': {
      const { attachments } = state;
      _.remove(attachments, { id: action.id });

      return {
        ...state,
        attachments,
      };
    }

    case 'NEWCONFERENCE_MOVE_ATTACHMENT': {
      /*
      ** DIRECTIONS:
      ** -----------
      ** UP   : -1
      ** DOWN : +1
      */

      const attachments = state.attachments.map(a => a);
      const index = _.findIndex(attachments, { id: action.id });
      const items = _.remove(attachments, { id: action.id });
      attachments.splice(index + action.direction, 0, items[0]);

      return {
        ...state,
        attachments,
      };
    }

    case 'NEWCONFERENCE_RESET_REQUESTED':
    case 'CONFERENCE_EDIT_REQUESTED': {
      return {
        ...state,
        isNewConference: true,
        id: null,
        isLoading: false,
        currentStep: 0,

        // STEP #1
        title: '',
        description: '',
        prices_dates: '',
        schedule: '',
        emailString: '',
        regOpen: null,
        regClose: null,
        availableTickets: 0,

        // STEP #2
        questions: [],

        // STEP #3
        attachments: [],
      };
    }

    case 'CONFERENCE_EDIT_SUCCEEDED': {
      return {
        ...state,
        isNewConference: false,
        id: action.conference.id,
        isLoading: false,
        currentStep: 0,


        // STEP #1
        title: action.conference.name,
        tab: action.conference.tab,
        description: action.conference.description,
        prices_dates: action.conference.prices_dates,
        schedule: action.conference.schedule,
        emailString: action.conference.email,
        isPreReg: action.conference.isPreReg,
        regOpen: action.conference.reg_open,
        regClose: action.conference.reg_close,
        notificationOpen: action.conference.notify_open,
        notificationClose: action.conference.notify_close,
        sicknessVisible: action.conference.sickness_visible,
        availableTickets: parseInt(action.conference.tickets, 10),

        // STEP #2
        questions: action.questions,

        // STEP #3
        attachments: action.attachments,
      };
    }

    default:
      return state;
  }
}


export default newConferenceReducer;
