import _ from 'lodash';
import {
  CONFERENCES_SUCCEEDED,
  ADMIN_CONFERENCES_SUCCEEDED,
  CONFERENCE_PROFILE_SUCCEEDED,
  CONFERENCE_DELETE_SUCCEEDED,
  CONFERENCE_APPLICATION_SUCCEEDED,
  CONFERENCE_APPLICATIONS_REQUESTED,
  CONFERENCE_APPLICATIONS_SUCCEEDED,
} from 'consts/actionTypes';
import initialState from './initialState';

const conferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFERENCES_SUCCEEDED: {
      return {
        ...state,
        conferences: action.conferences,
      };
    }

    case ADMIN_CONFERENCES_SUCCEEDED: {
      return {
        ...state,
        adminconferences: action.conferences,
      };
    }

    case CONFERENCE_PROFILE_SUCCEEDED: {
      return {
        ...state,
        currentProfile: action.conference,
      };
    }

    case CONFERENCE_DELETE_SUCCEEDED: {
      const conferenceList = state.adminconferences;

      _.remove(conferenceList, {
        id: action.conferenceId,
      });

      return {
        ...state,
        adminconferences: conferenceList.concat([]),
      };
    }

    case CONFERENCE_APPLICATION_SUCCEEDED: {
      return {
        ...state,
        currentApplication: action.application,
      };
    }

    case CONFERENCE_APPLICATIONS_REQUESTED: {
      return {
        ...state,
        currentApplications: null,
      };
    }

    case CONFERENCE_APPLICATIONS_SUCCEEDED: {
      return {
        ...state,
        currentApplications: _.remove(action.applications, application => (application.email !== null)),
      };
    }

    default:
      return state;
  }
};


export default conferencesReducer;
