import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, Steps } from 'antd';
import moment from 'moment';
import swal from 'sweetalert';
import Spinner from 'react-spinkit';
import { history } from 'store/store';
import ConferenceForm from 'components/ConferenceEditOrCreate/ConferenceForm/ConferenceForm';
import Questionnaire from 'components/ConferenceEditOrCreate/Questionnaire/Questionnaire';
import ConferenceAttachments from 'components/ConferenceEditOrCreate/ConferenceAttachments/ConferenceAttachments';
import Summary from 'components/ConferenceEditOrCreate/Summary/Summary';
import * as appActions from 'actions/appActions';
import * as newconferenceActions from 'actions/newconferenceActions';
import './addconference.scss';

const { Step } = Steps;


class EditConference extends React.Component {
  constructor(props) {
    super(props);

    if (props.newConference.id === null) {
      this.props.editConference(this.props.match.params.id);
    }

    this.setFocus = this.setFocus.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.checkFirstStep = this.checkFirstStep.bind(this);
    this.handleUpdateConferenceForm = this.handleUpdateConferenceForm.bind(this);
    this.renderNavigationButtons = this.renderNavigationButtons.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newConference.currentStep !== this.props.newConference.currentStep) {
      document.getElementsByClassName('ant-layout-content')[0].scrollTop = 0;
    }
  }

  setFocus(field) {
    setTimeout(() => {
      this[field].focus();
    }, 200);
  }

  nextStep() {
    const { currentStep } = this.props.newConference;

    if (currentStep === 0) {
      this.checkFirstStep();
      return;
    }

    this.props.nextStep();
  }

  prevStep() {
    this.props.prevStep();
  }

  checkFirstStep() {
    const {
      id, title, tab, description, prices_dates, schedule, // eslint-disable-line
      availableTickets,
      emailString,
      isPreReg,
      regOpen, regClose,
      notificationOpen, notificationClose,
    } = this.props.newConference;

    if (title === '') {
      swal({
        title: 'Hiba!',
        text: 'Az esemény címe nem lehet üres.',
        icon: 'error',
        button: {
          text: 'Javítom',
        },
      }, () => {
        this.setFocus('titleInput');
      });

      return;
    }

    if (availableTickets === null || availableTickets === '') {
      swal({
        title: 'Hiba!',
        text: 'Nem adta meg a szabad férőhelyek számát.',
        icon: 'error',
        button: {
          text: 'Javítom',
        },
      });
      return;
    }

    if (emailString === null) {
      swal({
        title: 'Hiba!',
        text: 'Nem adta meg a kiküldendő e-mail szövegét.',
        icon: 'error',
        button: {
          text: 'Javítom',
        },
      });
      return;
    }

    if (regOpen === null) {
      swal({
        title: 'Hiba!',
        text: 'Nem adott meg időpontot a regisztráció kezdetéhez.',
        icon: 'error',
        button: {
          text: 'Javítom',
        },
      });
      return;
    }

    if (regClose === null) {
      swal({
        title: 'Hiba!',
        text: 'Nem adott meg időpontot a regisztráció végéhez.',
        icon: 'error',
        button: {
          text: 'Javítom',
        },
      });
      return;
    }

    if (moment(regClose).isBefore(regOpen)) {
      swal({
        title: 'Hiba!',
        text: 'A vég időpont nem lehet a kezdeti időpont előtt.',
        icon: 'error',
        button: {
          text: 'Javítom',
        },
      });
      return;
    }

    if (id === null) {
      this.props.createConference({
        name: title,
        tab,
        description,
        prices_dates,
        schedule,
        availableTickets,
        isPreReg,
        regOpen,
        regClose,
        notificationOpen,
        notificationClose,
        emailString,
      });
    } else {
      this.props.updateConference({
        id,
        name: title,
        tab,
        description,
        prices_dates,
        schedule,
        availableTickets,
        isPreReg,
        regOpen,
        regClose,
        notificationOpen,
        notificationClose,
        emailString,
      });
    }
  }

  handleUpdateConferenceForm() {
    this.props.updateConferenceForm();
  }

  renderNavigationButtons(stepsLength) {
    const buttons = [];
    const { currentStep, isLoading } = this.props.newConference;

    if (currentStep === 0) {
      buttons.push(
        <Button
          key={1}
          size="large"
          onClick={() => history.push('/admin/conferences')}
        >
          Mégsem
        </Button>,
      );
    }

    if (currentStep > 0) {
      buttons.push(
        <Button
          key={1}
          size="large"
          onClick={() => this.prevStep()}
          loading={isLoading}
        >
          Vissza
        </Button>,
      );
    }

    if (currentStep < stepsLength - 1) {
      buttons.push(
        <Button
          key={2}
          size="large"
          type="primary"
          onClick={() => this.nextStep()}
          loading={this.props.newConference.isLoading}
        >
          Következő
        </Button>,
      );
    }

    if (currentStep === stepsLength - 1) {
      buttons.push(
        <Button
          key={3}
          size="large"
          type="primary"
          onClick={this.handleUpdateConferenceForm}
        >
          Mentés
        </Button>,
      );
    }

    return buttons;
  }

  render() {
    if (!this.props.user.admin) {
      history.push('/turnusok');
    }

    let regOpen = null;
    let regClose = null;
    let notificationOpen = null;
    let notificationClose = null;

    if (this.props.newConference.regOpen != null) {
      regOpen = moment(this.props.newConference.regOpen);
    }
    if (this.props.newConference.regClose != null) {
      regClose = moment(this.props.newConference.regClose);
    }
    if (this.props.newConference.notificationOpen != null) {
      notificationOpen = moment(this.props.newConference.notificationOpen);
    }
    if (this.props.newConference.notificationClose != null) {
      notificationClose = moment(this.props.newConference.notificationClose);
    }

    const { currentStep } = this.props.newConference;
    const steps = [
      {
        title: 'Alapadatok',
        content: (
          <ConferenceForm
            handleFieldChange={this.props.changeAttribute}
            title={this.props.newConference.title}
            tab={this.props.newConference.tab}
            description={this.props.newConference.description}
            prices_dates={this.props.newConference.prices_dates}
            schedule={this.props.newConference.schedule}
            isPreReg={this.props.newConference.isPreReg}
            regOpen={regOpen}
            regClose={regClose}
            notificationOpen={notificationOpen}
            notificationClose={notificationClose}
            availableTickets={this.props.newConference.availableTickets}
            sicknessVisible={this.props.newConference.sicknessVisible}
            emailString={this.props.newConference.emailString}
          />
        ),
      },
      {
        title: 'Kérdőív',
        content: (
          <Questionnaire
            questions={this.props.newConference.questions}
            addQuestion={this.props.addQuestion}
            updateQuestion={this.props.updateQuestion}
            moveQuestion={this.props.moveQuestion}
            deleteQuestion={this.props.deleteQuestion}
            deleteOption={this.props.deleteOption}
          />
        ),
      },
      {
        title: 'Mellékletek',
        content: (
          <ConferenceAttachments
            attachments={this.props.newConference.attachments}
            addAttachment={this.props.addAttachment}
            updateAttachment={this.props.updateAttachment}
            moveAttachment={this.props.moveAttachment}
            deleteAttachment={this.props.deleteAttachment}
          />
        ),
      },
      {
        title: 'Összegzés',
        content: (
          <Summary
            name={this.props.newConference.title}
            description={this.props.newConference.description}
            prices_dates={this.props.newConference.prices_dates}
            schedule={this.props.newConference.schedule}
            isPreReg={this.props.newConference.isPreReg}
            regOpen={this.props.newConference.regOpen}
            regClose={this.props.newConference.regClose}
            sicknessVisible={this.props.newConference.sicknessVisible}
            notificationOpen={this.props.newConference.notificationOpen}
            notificationClose={this.props.newConference.notificationClose}
            questions={this.props.newConference.questions}
            attachments={this.props.newConference.attachments}
            availableTickets={this.props.newConference.availableTickets}
            emailString={this.props.newConference.emailString}
          />
        ),
      },
    ];

    return (
      <div
        className="page page-add-conference"
      >
        <div className="page-header">
          <div className="page-header-wrap">Esemény szerkesztése</div>
        </div>

        <div className="page-content">
          <div className="page-content-wrap">
            <Steps current={currentStep}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>

            {this.props.newConference.id === null
              ? (
                <div className="loader-container">
                  <Spinner name="ball-beat" color="#23b7e5" />
                  <div className="loader-title">Az turnusok betöltése folyamatban van...</div>
                </div>
              )
              : (
                <React.Fragment>
                  <div key="steps-content" className="steps-content">
                    {steps[currentStep].content}
                  </div>
                  <div key="steps-action" className="steps-action">
                    {this.renderNavigationButtons(steps.length)}
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

EditConference.defaultProps = {
  user: {
    admin: false,
  },
};

EditConference.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  editConference: PropTypes.func.isRequired,
  createConference: PropTypes.func.isRequired,
  updateConference: PropTypes.func.isRequired,
  updateConferenceForm: PropTypes.func.isRequired,
  changeAttribute: PropTypes.func.isRequired,
  addQuestion: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  moveAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  user: PropTypes.shape({
    admin: PropTypes.bool,
  }),
  newConference: PropTypes.shape({
    currentStep: PropTypes.number.isRequired,
    id: PropTypes.string,
    title: PropTypes.string,
    tab: PropTypes.string,
    description: PropTypes.string,
    prices_dates: PropTypes.string,
    schedule: PropTypes.string,
    isPreReg: PropTypes.bool,
    regOpen: PropTypes.string,
    regClose: PropTypes.string,
    sicknessVisible: PropTypes.bool,
    notificationOpen: PropTypes.string,
    notificationClose: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape()),
    attachments: PropTypes.arrayOf(PropTypes.shape()),
    emailString: PropTypes.string,
    availableTickets: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = store => ({
  newConference: store.newConference,
});

const mapDispatchToProps = {
  getConferences: appActions.getConferences,
  getConference: appActions.getConference,
  editConference: appActions.editConference,
  nextStep: newconferenceActions.nextStep,
  prevStep: newconferenceActions.prevStep,
  createConference: newconferenceActions.createConference,
  updateConference: newconferenceActions.updateConference,
  submitConference: newconferenceActions.submitConference,
  updateConferenceForm: newconferenceActions.updateConferenceForm,
  changeAttribute: newconferenceActions.changeAttribute,
  addQuestion: newconferenceActions.addQuestion,
  updateQuestion: newconferenceActions.updateQuestion,
  moveQuestion: newconferenceActions.moveQuestion,
  deleteQuestion: newconferenceActions.deleteQuestion,
  deleteOption: newconferenceActions.deleteOption,
  addAttachment: newconferenceActions.addAttachment,
  updateAttachment: newconferenceActions.updateAttachment,
  moveAttachment: newconferenceActions.moveAttachment,
  deleteAttachment: newconferenceActions.deleteAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditConference));
