import {
  USER_LOGIN_REQUESTED, USER_LOGOUT_REQUESTED, RESTORE_USER_BY_TOKEN_REQUESTED,
} from 'consts/actionTypes';

export const login = (email, password, next) => ({
  type: USER_LOGIN_REQUESTED,
  payload: {
    email,
    password,
    next,
  },
});

export const logout = () => ({
  type: USER_LOGOUT_REQUESTED,
});

export const restoreUserByToken = next => ({
  type: RESTORE_USER_BY_TOKEN_REQUESTED,
  next,
});
