import qs from 'qs';
import { checkHttpStatus, parseJSON } from 'utils/utils';
import { host } from 'store/store';

export function login(email, password) {
  return fetch(`${host}/users/checklogin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: qs.stringify({
      email,
      password,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function signup(email, password) {
  return fetch(`${host}/users/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: qs.stringify({
      email,
      password,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function validateToken(token = localStorage.getItem('token')) {
  return fetch(`${host}/users/checkuserid`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getConferences(filters) {
  const filterString = filters.join('&');
  return fetch(`${host}/conferences/getconferences?${filterString}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getAdminConferences() {
  return fetch(`${host}/conferences/getadminconferences`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getConference(conferenceId) {
  return fetch(`${host}/conferences/details?id=${conferenceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getConferenceForm(conferenceId) {
  return fetch(`${host}/conferences/getform?id=${conferenceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getConferenceAttachments(conferenceId) {
  return fetch(`${host}/files/getfiles?conference_id=${conferenceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function createConference(fields) {
  return fetch(`${host}/conferences/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      name: fields.name,
      tab: fields.tab,
      description: fields.description,
      prices_dates: fields.prices_dates,
      schedule: fields.schedule,
      isPreReg: fields.isPreReg ? 1 : 0,
      reg_open: fields.regOpen,
      reg_close: fields.regClose,
      notify_open: fields.notificationOpen,
      notify_close: fields.notificationClose,
      sickness_visible: fields.sicknessVisible ? 1 : 0,
      tickets: fields.availableTickets,
      email: fields.emailString,
      isDraft: 1,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function updateConference(fields) {
  return fetch(`${host}/conferences/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      id: fields.id,
      name: fields.name,
      tab: fields.tab,
      description: fields.description,
      prices_dates: fields.prices_dates,
      schedule: fields.schedule,
      isPreReg: fields.isPreReg ? 1 : 0,
      reg_open: fields.regOpen,
      reg_close: fields.regClose,
      notify_open: fields.notificationOpen,
      notify_close: fields.notificationClose,
      sickness_visible: fields.sicknessVisible ? 1 : 0,
      tickets: fields.availableTickets,
      email: fields.emailString,
      isDraft: 1,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function updateConferenceForm(conferenceId, fields, files) {
  return fetch(`${host}/forms/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference_id: conferenceId,
        fields,
        files,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function submitConference(conferenceId, fields, files) {
  return fetch(`${host}/forms/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference_id: conferenceId,
        fields,
        files,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function deleteQuestion(id) {
  return fetch(`${host}/forms/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      id,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function deleteOption(id) {
  return fetch(`${host}/forms/deleteoption`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      id,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function deleteAttachmentField(id) {
  return fetch(`${host}/files/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      id,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function cloneConference(conferenceId) {
  return fetch(`${host}/conferences/clone`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference: conferenceId,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function deleteConference(id) {
  return fetch(`${host}/conferences/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      id,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function applyForConference(conferenceId, userData, diseaseData, formAnswers) {
  return fetch(`${host}/conferences/apply`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference: conferenceId,
        user: {
          ...userData,
          have_disease: diseaseData.haveDisease,
          disease: diseaseData.disease,
        },
        answers: formAnswers,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function createApplySlot(conferenceId) {
  return fetch(`${host}/conferences/apply_blank`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference: conferenceId,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response.application_id))
    .catch((error) => {
      throw error;
    });
}

export function updateApplication(conferenceId, applicationId, userData, diseaseData, formAnswers) {
  return fetch(`${host}/conferences/modifyapplication`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference: conferenceId,
        application: applicationId,
        user: {
          ...userData,
          have_disease: diseaseData.haveDisease,
          disease: diseaseData.disease,
        },
        answers: formAnswers,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function cancelApplyForConference(conferenceId, applicationId) {
  return fetch(`${host}/conferences/cancelapplication`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: qs.stringify({
      json: JSON.stringify({
        conference: conferenceId,
        application_id: applicationId,
      }),
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getApplications(conferenceId) {
  return fetch(`${host}/conferences/applications?id=${conferenceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function getApplication(conferenceId, applicationId) {
  return fetch(`${host}/conferences/get_application?id=${conferenceId}&application_id=${applicationId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function createWebExport(conferenceId) {
  return fetch(`${host}/conferences/webexport?id=${conferenceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function createXlsExport(conferenceId) {
  return fetch(`${host}/conferences/xlsexport?id=${conferenceId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then(checkHttpStatus)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function requestPassword(email) {
  return fetch(`${host}/users/resetpassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: qs.stringify({
      email,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}

export function changePassword(currentPassword, newPassword) {
  return fetch(`${host}/users/setpassword`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: qs.stringify({
      current_password: currentPassword,
      new_password: newPassword,
    }),
  })
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => (response))
    .catch((error) => {
      throw error;
    });
}
