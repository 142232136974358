// USER ACTION
export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const GET_USER_BY_TOKEN_REQUESTED = 'GET_USER_BY_TOKEN_REQUESTED';
export const RESTORE_USER_BY_TOKEN_REQUESTED = 'RESTORE_USER_BY_TOKEN_REQUESTED';
export const RESTORE_USER_BY_TOKEN_SUCCEEDED = 'RESTORE_USER_BY_TOKEN_SUCCEEDED';
export const USER_LOGOUT_REQUESTED = 'USER_LOGOUT_REQUESTED';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';

export const USER_SIGNUP_REQUESTED = 'USER_SIGNUP_REQUESTED';
export const USER_SIGNUP_SUCCEEDED = 'USER_SIGNUP_SUCCEEDED';
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED';

export const ADMIN_CONFERENCES_REQUESTED = 'ADMIN_CONFERENCES_REQUESTED';
export const ADMIN_CONFERENCES_SUCCEEDED = 'ADMIN_CONFERENCES_SUCCEEDED';
export const ADMIN_CONFERENCES_FAILED = 'ADMIN_CONFERENCES_FAILED';

export const CONFERENCES_REQUESTED = 'CONFERENCES_REQUESTED';
export const CONFERENCES_SUCCEEDED = 'CONFERENCES_SUCCEEDED';
export const CONFERENCES_FAILED = 'CONFERENCES_FAILED';

export const CONFERENCE_PROFILE_REQUESTED = 'CONFERENCE_PROFILE_REQUESTED';
export const CONFERENCE_PROFILE_SUCCEEDED = 'CONFERENCE_PROFILE_SUCCEEDED';
export const CONFERENCE_PROFILE_FAILED = 'CONFERENCE_PROFILE_FAILED';
export const CONFERENCE_DELETE_REQUESTED = 'CONFERENCE_DELETE_REQUESTED';
export const CONFERENCE_DELETE_SUCCEEDED = 'CONFERENCE_DELETE_SUCCEEDED';
export const CONFERENCE_DELETE_FAILED = 'CONFERENCE_DELETE_FAILED';
export const CONFERENCE_APPLICATION_REQUESTED = 'CONFERENCE_APPLICATION_REQUESTED';
export const CONFERENCE_APPLICATION_SUCCEEDED = 'CONFERENCE_APPLICATION_SUCCEEDED';
export const CONFERENCE_APPLICATION_FAILED = 'CONFERENCE_APPLICATION_FAILED';
export const CONFERENCE_APPLICATIONS_REQUESTED = 'CONFERENCE_APPLICATIONS_REQUESTED';
export const CONFERENCE_APPLICATIONS_SUCCEEDED = 'CONFERENCE_APPLICATIONS_SUCCEEDED';
export const CONFERENCE_APPLICATIONS_FAILED = 'CONFERENCE_APPLICATIONS_FAILED';

export const PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED';
export const PASSWORD_CHANGE_REQUESTED = 'PASSWORD_CHANGE_REQUESTED';
export const CONFERENCE_EDIT_REQUESTED = 'CONFERENCE_EDIT_REQUESTED';
export const CONFERENCE_CLONE_REQUESTED = 'CONFERENCE_CLONE_REQUESTED';
export const CONFERENCE_APPLY_REQUESTED = 'CONFERENCE_APPLY_REQUESTED';
export const CONFERENCE_APPLY_SLOT_REQUESTED = 'CONFERENCE_APPLY_SLOT_REQUESTED';
export const CONFERENCE_CANCEL_APPLY_REQUESTED = 'CONFERENCE_CANCEL_APPLY_REQUESTED';
export const CONFERENCE_UPDATE_APPLY_REQUESTED = 'CONFERENCE_UPDATE_APPLY_REQUESTED';
export const CONFERENCE_CREATE_WEBEXPORT_REQUESTED = 'CONFERENCE_CREATE_WEBEXPORT_REQUESTED';
export const NEWCONFERENCE_CREATE_REQUESTED = 'NEWCONFERENCE_CREATE_REQUESTED';
export const NEWCONFERENCE_UPDATE_REQUESTED = 'NEWCONFERENCE_UPDATE_REQUESTED';
export const NEWCONFERENCE_SUBMIT_REQUESTED = 'NEWCONFERENCE_SUBMIT_REQUESTED';
export const NEWCONFERENCE_UPDATE_FORM_REQUESTED = 'NEWCONFERENCE_UPDATE_FORM_REQUESTED';
export const NEWCONFERENCE_DELETE_QUESTION_REQUESTED = 'NEWCONFERENCE_DELETE_QUESTION_REQUESTED';
export const NEWCONFERENCE_DELETE_ATTACHMENT_REQUESTED = 'NEWCONFERENCE_DELETE_ATTACHMENT_REQUESTED';
export const NEWCONFERENCE_DELETE_OPTION_REQUESTED = 'NEWCONFERENCE_DELETE_OPTION_REQUESTED';

export const NEWCONFERENCE_ADD_ATTACHMENT = 'NEWCONFERENCE_ADD_ATTACHMENT';
export const NEWCONFERENCE_ADD_QUESTION = 'NEWCONFERENCE_ADD_QUESTION';
export const NEWCONFERENCE_CHANGE_ATTRIBUTE = 'NEWCONFERENCE_CHANGE_ATTRIBUTE';
export const NEWCONFERENCE_MOVE_ATTACHMENT = 'NEWCONFERENCE_MOVE_ATTACHMENT';
export const NEWCONFERENCE_MOVE_QUESTION = 'NEWCONFERENCE_MOVE_QUESTION';
export const NEWCONFERENCE_NEXT_STEP = 'NEWCONFERENCE_NEXT_STEP';
export const NEWCONFERENCE_PREV_STEP = 'NEWCONFERENCE_PREV_STEP';
export const NEWCONFERENCE_RESET_REQUESTED = 'NEWCONFERENCE_RESET_REQUESTED';
export const NEWCONFERENCE_UPDATE_ATTACHMENT = 'NEWCONFERENCE_UPDATE_ATTACHMENT';
export const NEWCONFERENCE_UPDATE_QUESTION = 'NEWCONFERENCE_UPDATE_QUESTION';


// OTHER ACTIONS
export const APP_CHANGE_LANGUAGE = 'APP_CHANGE_LANGUAGE';
