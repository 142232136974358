import {
  USER_LOGIN_REQUESTED, USER_LOGIN_FAILED, USER_LOGIN_SUCCEEDED,
  RESTORE_USER_BY_TOKEN_REQUESTED, RESTORE_USER_BY_TOKEN_SUCCEEDED,
  USER_SIGNUP_REQUESTED,
  GET_USER_BY_TOKEN_REQUESTED,
  CONFERENCES_REQUESTED,
  ADMIN_CONFERENCES_REQUESTED,
  NEWCONFERENCE_CREATE_REQUESTED,
  CONFERENCE_EDIT_REQUESTED,
  NEWCONFERENCE_UPDATE_REQUESTED,
  NEWCONFERENCE_SUBMIT_REQUESTED,
  NEWCONFERENCE_UPDATE_FORM_REQUESTED,
  CONFERENCE_PROFILE_REQUESTED,
  CONFERENCE_CLONE_REQUESTED,
  CONFERENCE_DELETE_REQUESTED,
  CONFERENCE_APPLY_REQUESTED,
  CONFERENCE_APPLY_SLOT_REQUESTED,
  CONFERENCE_UPDATE_APPLY_REQUESTED,
  CONFERENCE_CANCEL_APPLY_REQUESTED,
  NEWCONFERENCE_DELETE_QUESTION_REQUESTED,
  NEWCONFERENCE_DELETE_ATTACHMENT_REQUESTED,
  NEWCONFERENCE_DELETE_OPTION_REQUESTED,
  CONFERENCE_APPLICATIONS_REQUESTED,
  CONFERENCE_APPLICATION_REQUESTED,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_CHANGE_REQUESTED,
  USER_LOGOUT_REQUESTED, USER_LOGOUT_SUCCEEDED,
} from 'consts/actionTypes';
import { take, takeEvery, call, put, select, delay } from 'redux-saga/effects';
import i18next from 'i18next';
import { history } from 'store/store';
import _ from 'lodash';
import swal from 'sweetalert';
import * as Api from 'services/api';

const getNewConferenceStore = state => state.newConference;


// GET USER
function* validateToken(token, next) {
  try {
    const credentials = yield call(Api.validateToken, token);
    return credentials;
  } catch (error) {
    yield put({ type: 'USER_VALIDATION_ERROR', error });
    localStorage.removeItem('token');
    history.push(`/?redirect=${next}`);
    throw error;
  }
}

export function* getUserByToken(action) {
  const { token } = action;
  const credentials = yield call(validateToken, token, '/turnusok');

  if (credentials) {
    yield put({
      type: RESTORE_USER_BY_TOKEN_SUCCEEDED,
      credentials,
    });
  }
}

export function* restoreUserByToken(action) {
  try {
    const { next } = action;
    const credentials = yield call(validateToken, localStorage.getItem('token'), next);
    if (credentials) {
      yield put({
        type: RESTORE_USER_BY_TOKEN_SUCCEEDED,
        credentials,
      });
      history.push(next);
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

// LOGIN
function* authorize(email, password) {
  try {
    const token = yield call(Api.login, email, password);
    return token;
  } catch (error) {
    throw error;
  }
}

export function* loginUser(action) {
  try {
    const { email, password, next } = action.payload;
    yield delay(1000);
    const token = yield call(authorize, email, password);
    if (token) {
      // GET USER CREDENTIALS BY TOKEN
      yield put({
        type: 'GET_USER_BY_TOKEN_REQUESTED',
        token: token.access_token,
      });

      // SAVE USER
      const { credentials } = yield take(RESTORE_USER_BY_TOKEN_SUCCEEDED);
      yield put({
        type: USER_LOGIN_SUCCEEDED,
        token,
        credentials,
      });
      localStorage.setItem('token', token.access_token);
      history.push(next || '/turnusok');
    }
  } catch (error) {
    const errorObject = {
      type: USER_LOGIN_FAILED,
      message: null,
      error,
    };

    switch (error.status) {
      case 403: {
        errorObject.message = i18next.t('errors.login.403');
        break;
      }

      default: errorObject.message = i18next.t('errors.login.server_error');
    }

    yield put(errorObject);
  }
}


// SIGUP
export function* registrate(email, password) {
  try {
    const credentials = yield call(Api.signup, email, password);
    return credentials;
  } catch (error) {
    yield put({ type: 'SIGUP_ERROR', error });
    throw error;
  }
}

export function* signupUser(action) {
  try {
    const { email, password } = action;
    const credentials = yield call(registrate, email, password);
    if (credentials) {
      yield put({
        type: 'USER_LOGIN_REQUESTED',
        payload: {
          email,
          password,
        },
      });
    }
  } catch (error) {
    swal({
      title: 'Hiba!',
      text: 'Ez a felhasználói fiók már létezik.',
      icon: 'error',
      button: {
        text: 'Bezár',
      },
    });
    console.log(error); // eslint-disable-line
  }
}


// GET CONFERENCES
export function* getConferences(action) {
  try {
    const conferences = yield call(Api.getConferences, action.payload.filters);
    yield put({
      type: 'CONFERENCES_SUCCEEDED',
      conferences: conferences.data,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// GET ADMIN CONFERENCES
export function* getAdminConferences() {
  try {
    const conferences = yield call(Api.getAdminConferences);
    yield put({
      type: 'ADMIN_CONFERENCES_SUCCEEDED',
      conferences: conferences.data,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// CREATE CONFERENCE
export function* createConference(action) {
  try {
    const newConference = yield call(Api.createConference, action.fields);
    yield put({
      type: 'NEWCONFERENCE_CREATED',
      id: newConference.id,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// EDIT CONFERENCE
function processFormData(question) {
  const processedQuestion = question;

  switch (question.type) {
    // text input
    case '1': {
      processedQuestion.type = 'text';
      break;
    }

    // checkbox input
    case '2': {
      processedQuestion.type = 'checkbox';
      break;
    }

    // dropdown input
    case '3': {
      processedQuestion.type = 'dropdown';
      break;
    }

    // texarea input
    case '4': {
      processedQuestion.type = 'textarea';
      break;
    }

    // date input
    case '5': {
      processedQuestion.type = 'date';
      break;
    }

    default: break;
  }

  processedQuestion.id = parseInt(question.id, 10);
  processedQuestion.isRequired = Boolean(Number(question.required));
  processedQuestion.isSaved = true;

  if (question.options.length !== 0) {
    const optionValues = [];

    question.options.forEach((option) => {
      optionValues.push({
        id: option.value,
        value: option.text,
        isSaved: true,
      });
    });

    processedQuestion.options = optionValues;
  }

  return processedQuestion;
}

export function* editConference(action) {
  try {
    const conferenceData = yield call(Api.getConference, action.conferenceId);

    const conferenceForm = yield call(Api.getConferenceForm, action.conferenceId);
    const questions = [];
    conferenceForm.data.forEach((question) => {
      questions.push(processFormData(question));
    });

    const conferenceAttachment = yield call(Api.getConferenceAttachments, action.conferenceId);
    const fields = _.reverse(conferenceAttachment.data);
    const attachments = [];
    fields.forEach((attachment) => {
      attachments.push({
        id: parseInt(attachment.id, 10),
        title: attachment.name,
        isRequired: Boolean(Number(attachment.required)),
        isUploaded: false,
        htmlContent: null,
        isSaved: true,
      });
    });

    yield put({
      type: 'CONFERENCE_EDIT_SUCCEEDED',
      conference: conferenceData.data,
      questions,
      attachments,
    });

    history.push(`/admin/conferences/${action.conferenceId}/edit`);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// UPDATE CONFERENCE
export function* updateConference(action) {
  try {
    const newConference = yield call(Api.updateConference, action.fields);
    yield put({
      type: 'NEWCONFERENCE_UPDATED',
      id: newConference.id,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// SUBMIT CONFERENCE QUESTIONS AND ATTACHMENTS
export function* submitConference() {
  try {
    const store = yield select(getNewConferenceStore);
    const fields = [];
    const files = [];
    const questionTypes = {
      text: 1, // text input
      checkbox: 2, // checkbox
      dropdown: 3, // dropdown
      textarea: 4, // textarea
      date: 5, // date
    };

    store.questions.forEach((question) => {
      const options = [];

      const field = {
        type: questionTypes[question.type],
        title: question.title,
        required: question.isRequired ? 1 : 0,
        isDraft: 0,
      };

      question.options.forEach((option) => {
        options.push(option.value);
      });

      if (options.length > 0) {
        field.options = options;
      }

      fields.push(field);
    });

    store.attachments.forEach((attachment) => {
      files.push({
        name: attachment.title,
        required: attachment.isRequired ? 1 : 0,
      });
    });

    yield call(Api.submitConference, store.id, fields, files);
    swal({
      icon: 'success',
      text: 'Az eseményt létrehoztuk',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    history.push('/admin/conferences');
  } catch (error) {
    swal({
      icon: 'error',
      text: 'Hiba történt az esemény létrehozásakor',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    console.log(error); // eslint-disable-line
  }
}


// UPDATE CONFERENCE QUESTIONS AND ATTACHMENTS
export function* updateConferenceForm() {
  try {
    const store = yield select(getNewConferenceStore);
    const fields = [];
    const files = [];
    const questionTypes = {
      text: 1, // text input
      checkbox: 2, // checkbox
      dropdown: 3, // dropdown
      textarea: 4, // textarea
      date: 5, // date
    };

    store.questions.forEach((question, index) => {
      const options = [];

      const field = {
        id: question.id,
        type: questionTypes[question.type],
        title: question.title,
        required: question.isRequired ? 1 : 0,
        index: index + 1,
        isDraft: 0,
      };

      question.options.forEach((option) => {
        options.push({
          id: option.id,
          value: option.value,
        });
      });

      if (options.length > 0) {
        field.options = options;
      }

      fields.push(field);
    });

    store.attachments.forEach((attachment) => {
      files.push({
        id: attachment.id,
        name: attachment.title,
        required: attachment.isRequired ? 1 : 0,
      });
    });

    yield call(Api.updateConferenceForm, store.id, fields, files);
    swal({
      icon: 'success',
      text: 'Az eseményt mentettük',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    history.push('/admin/conferences');
  } catch (error) {
    swal({
      icon: 'error',
      text: 'Hiba történt az esemény létrehozásakor',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    console.log(error); // eslint-disable-line
  }
}


// GET CONFERENCE PROFILE
export function* getConference(action) {
  try {
    const conference = yield call(Api.getConference, action.conferenceId);
    yield put({
      type: 'CONFERENCE_PROFILE_SUCCEEDED',
      conference: conference.data,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// DELETE CONFERENCE
export function* cloneConference(action) {
  try {
    yield call(Api.cloneConference, action.conferenceId);
    yield put({
      type: 'ADMIN_CONFERENCES_REQUESTED',
    });
    swal({
      icon: 'success',
      text: 'Az eseményt klónoztuk',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// DELETE CONFERENCE
export function* deleteConference(action) {
  try {
    yield call(Api.deleteConference, action.conferenceId);
    yield put({
      type: 'CONFERENCE_DELETE_SUCCEEDED',
      conferenceId: action.conferenceId,
    });
    yield put({
      type: 'ADMIN_CONFERENCES_REQUESTED',
    });
    swal({
      icon: 'success',
      text: 'Az eseményt töröltük',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// APPLY FOR CONFERENCE
export function* applyForConference(action) {
  try {
    yield call(Api.applyForConference, action.conferenceId, action.userData, action.diseaseData, action.formAnswers);
    yield put({
      type: 'CONFERENCE_APPLICATION_REQUESTED',
      conferenceId: action.conferenceId,
    });
    swal({
      icon: 'success',
      text: 'A jelentkezés sikeres volt',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    history.push(`/conferences/${action.conferenceId.id}?tab=applications`);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// CREATE APPLY SLOT
export function* createApplySlot(action) {
  try {
    const applicationId = yield call(Api.createApplySlot, action.conferenceId);
    history.push(`/conferences/${action.conferenceId.id}/applications/${applicationId}`);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// UPDATE APPLICATION FOR CONFERENCE
export function* updateApplication(action) {
  try {
    yield call(Api.updateApplication, action.conferenceId, action.applicationId, action.userData, action.diseaseData, action.formAnswers);
    yield put({
      type: 'CONFERENCE_APPLICATIONS_REQUESTED',
      conferenceId: action.conferenceId.id,
    });
    swal({
      icon: 'success',
      text: 'A jelentkezés sikeres volt',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    if (action.isAdminRoute) {
      history.push(`/admin/conferences/${action.conferenceId.id}/profile?tab=applications`);
    } else {
      history.push(`/conferences/${action.conferenceId.id}?tab=applications`);
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// CANCEL APPLICATION FOR CONFERENCE
export function* cancelApplyForConference(action) {
  try {
    yield call(Api.cancelApplyForConference, action.conferenceId, action.applicationId);
    yield put({
      type: 'CONFERENCES_REQUESTED',
    });
    yield put({
      type: 'CONFERENCE_APPLICATIONS_REQUESTED',
      conferenceId: action.conferenceId,
    });

    swal({
      icon: 'success',
      text: 'A jelentkezés törlése sikeres volt',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });

    if (!action.isAdmin) {
      history.push(action.redirect || '/turnusok');
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// DELETE QUESTION
export function* deleteQuestion(action) {
  const store = yield select(getNewConferenceStore);
  const removedQuestion = _.find(store.questions, { id: action.id });

  try {
    if (removedQuestion.isSaved) {
      yield call(Api.deleteQuestion, action.id);
    }
    yield put({
      type: 'NEWCONFERENCE_DELETE_QUESTION_SUCCEDED',
      id: action.id,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// DELETE ATTACHMENT FIELD
export function* deleteAttachment(action) {
  const store = yield select(getNewConferenceStore);
  const removedAttachment = _.find(store.attachments, { id: action.id });

  try {
    if (removedAttachment.isSaved) {
      yield call(Api.deleteAttachmentField, action.id);
    }
    yield put({
      type: 'NEWCONFERENCE_DELETE_ATTACHMENT_SUCCEDED',
      id: action.id,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// DELETE OPTION
export function* deleteOption(action) {
  try {
    yield call(Api.deleteOption, action.id);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// GET APPLICATIONS FOR CONFERENCE
export function* getApplications(action) {
  try {
    const applications = yield call(Api.getApplications, action.conferenceId);
    yield put({
      type: 'CONFERENCE_APPLICATIONS_SUCCEEDED',
      applications: applications.data,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// GET APPLICATION
export function* getApplication(action) {
  try {
    const application = yield call(Api.getApplication, action.conferenceId, action.applicationId);
    yield put({
      type: 'CONFERENCE_APPLICATION_SUCCEEDED',
      application: application.data,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}


// REQUEST NEW PASSWORD
export function* requestPassword(action) {
  try {
    yield call(Api.requestPassword, action.email);
    yield put({
      type: 'PASSWORD_RESET_SUCCEEDED',
    });
    history.push('/forgotten-password/sent');
  } catch (error) {
    if (error.message === 'User not found.') {
      swal({
        icon: 'error',
        text: 'Ezzel az email címmel nem létezik felhasználó.',
        buttons: false,
        closeOnClickOutside: true,
        closeOnEsc: true,
        timer: 3000,
      });
    }
    console.log(error); // eslint-disable-line
  }
}


// REQUEST PASSWORD CHANGE
export function* changePassword(action) {
  try {
    yield call(Api.changePassword, action.currentPassword, action.newPassword);
    swal({
      icon: 'success',
      text: 'Jelszavát módosítottuk',
      buttons: false,
      closeOnClickOutside: false,
      closeOnEsc: false,
      timer: 2000,
    });
    yield put({
      type: 'PASSWORD_CHANGE_SUCCEEDED',
    });
    history.push('/turnusok');
  } catch (error) {
    if (error.message === 'Wrong current password was given.') {
      swal({
        icon: 'error',
        text: 'Hibásan adta meg jelenlegi jelszavát.',
        buttons: false,
        closeOnClickOutside: true,
        closeOnEsc: true,
        timer: 3000,
      });
    }
    console.log(error); // eslint-disable-line
  }
}


// LOGOUT

export function* logout() {
  localStorage.removeItem('token');
  history.push('/login');
  yield put({
    type: USER_LOGOUT_SUCCEEDED,
  });
}

export default function* rootSaga() {
  yield takeEvery(USER_LOGIN_REQUESTED, loginUser);
  yield takeEvery(RESTORE_USER_BY_TOKEN_REQUESTED, restoreUserByToken);
  yield takeEvery(USER_SIGNUP_REQUESTED, signupUser);
  yield takeEvery(GET_USER_BY_TOKEN_REQUESTED, getUserByToken);
  yield takeEvery(CONFERENCES_REQUESTED, getConferences);
  yield takeEvery(ADMIN_CONFERENCES_REQUESTED, getAdminConferences);
  yield takeEvery(NEWCONFERENCE_CREATE_REQUESTED, createConference);
  yield takeEvery(CONFERENCE_EDIT_REQUESTED, editConference);
  yield takeEvery(NEWCONFERENCE_UPDATE_REQUESTED, updateConference);
  yield takeEvery(NEWCONFERENCE_SUBMIT_REQUESTED, submitConference);
  yield takeEvery(NEWCONFERENCE_UPDATE_FORM_REQUESTED, updateConferenceForm);
  yield takeEvery(CONFERENCE_PROFILE_REQUESTED, getConference);
  yield takeEvery(CONFERENCE_CLONE_REQUESTED, cloneConference);
  yield takeEvery(CONFERENCE_DELETE_REQUESTED, deleteConference);
  yield takeEvery(CONFERENCE_APPLY_REQUESTED, applyForConference);
  yield takeEvery(CONFERENCE_APPLY_SLOT_REQUESTED, createApplySlot);
  yield takeEvery(CONFERENCE_UPDATE_APPLY_REQUESTED, updateApplication);
  yield takeEvery(CONFERENCE_CANCEL_APPLY_REQUESTED, cancelApplyForConference);
  yield takeEvery(NEWCONFERENCE_DELETE_QUESTION_REQUESTED, deleteQuestion);
  yield takeEvery(NEWCONFERENCE_DELETE_ATTACHMENT_REQUESTED, deleteAttachment);
  yield takeEvery(NEWCONFERENCE_DELETE_OPTION_REQUESTED, deleteOption);
  yield takeEvery(CONFERENCE_APPLICATIONS_REQUESTED, getApplications);
  yield takeEvery(CONFERENCE_APPLICATION_REQUESTED, getApplication);
  yield takeEvery(PASSWORD_RESET_REQUESTED, requestPassword);
  yield takeEvery(PASSWORD_CHANGE_REQUESTED, changePassword);
  yield takeEvery(USER_LOGOUT_REQUESTED, logout);
}
