import {
  APP_CHANGE_LANGUAGE,
  USER_LOGIN_REQUESTED,
  USER_LOGOUT_REQUESTED,
  USER_SIGNUP_REQUESTED,
  GET_USER_BY_TOKEN_REQUESTED,
  RESTORE_USER_BY_TOKEN_REQUESTED,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_CHANGE_REQUESTED,
  CONFERENCES_REQUESTED,
  ADMIN_CONFERENCES_REQUESTED,
  CONFERENCE_PROFILE_REQUESTED,
  CONFERENCE_EDIT_REQUESTED,
  CONFERENCE_DELETE_REQUESTED,
  CONFERENCE_CLONE_REQUESTED,
  CONFERENCE_APPLICATION_REQUESTED,
  CONFERENCE_APPLICATIONS_REQUESTED,
  CONFERENCE_APPLY_REQUESTED,
  CONFERENCE_APPLY_SLOT_REQUESTED,
  CONFERENCE_CANCEL_APPLY_REQUESTED,
  CONFERENCE_UPDATE_APPLY_REQUESTED,
  CONFERENCE_CREATE_WEBEXPORT_REQUESTED,
} from 'consts/actionTypes';


export const changeLanguage = lang => ({
  type: APP_CHANGE_LANGUAGE,
  payload: {
    lang,
  },
});

export function userLogin(email, password, next) {
  return {
    type: USER_LOGIN_REQUESTED,
    payload: {
      email,
      password,
      next,
    },
  };
}

export function userLogout() {
  return {
    type: USER_LOGOUT_REQUESTED,
  };
}

export function userSignup(email, password) {
  return {
    type: USER_SIGNUP_REQUESTED,
    email,
    password,
  };
}

export function getUserByToken(token) {
  return {
    type: GET_USER_BY_TOKEN_REQUESTED,
    token,
  };
}

export function restoreUserByToken(next) {
  return {
    type: RESTORE_USER_BY_TOKEN_REQUESTED,
    next,
  };
}

export function resetPassword(email) {
  return {
    type: PASSWORD_RESET_REQUESTED,
    email,
  };
}

export function changePassword(currentPassword, newPassword) {
  return {
    type: PASSWORD_CHANGE_REQUESTED,
    currentPassword,
    newPassword,
  };
}

export function getConferences(filters) {
  return {
    type: CONFERENCES_REQUESTED,
    payload: {
      filters,
    },
  };
}

export function getAdminConferences() {
  return {
    type: ADMIN_CONFERENCES_REQUESTED,
  };
}

export function getConference(conferenceId) {
  return {
    type: CONFERENCE_PROFILE_REQUESTED,
    conferenceId,
  };
}

export function editConference(conferenceId) {
  return {
    type: CONFERENCE_EDIT_REQUESTED,
    conferenceId,
  };
}

export function deleteConference(conferenceId) {
  return {
    type: CONFERENCE_DELETE_REQUESTED,
    conferenceId,
  };
}

export function cloneConference(conferenceId) {
  return {
    type: CONFERENCE_CLONE_REQUESTED,
    conferenceId,
  };
}

export function getApplication(conferenceId, applicationId) {
  return {
    type: CONFERENCE_APPLICATION_REQUESTED,
    conferenceId,
    applicationId,
  };
}

export function getApplications(conferenceId) {
  return {
    type: CONFERENCE_APPLICATIONS_REQUESTED,
    conferenceId,
  };
}

export function applyForConference(conferenceId, userData, diseaseData, formAnswers) {
  return {
    type: CONFERENCE_APPLY_REQUESTED,
    conferenceId,
    userData,
    diseaseData,
    formAnswers,
  };
}

export function createApplicationSlot(conferenceId) {
  return {
    type: CONFERENCE_APPLY_SLOT_REQUESTED,
    conferenceId,
  };
}

export function cancelApplication(conferenceId, applicationId, isAdmin = false) {
  return {
    type: CONFERENCE_CANCEL_APPLY_REQUESTED,
    conferenceId,
    applicationId,
    isAdmin,
  };
}

export function updateApplication(conferenceId, applicationId, userData, diseaseData, formAnswers, isAdminRoute) {
  return {
    type: CONFERENCE_UPDATE_APPLY_REQUESTED,
    conferenceId,
    applicationId,
    userData,
    diseaseData,
    formAnswers,
    isAdminRoute,
  };
}

export function createWebExport(conferenceId) {
  return {
    type: CONFERENCE_CREATE_WEBEXPORT_REQUESTED,
    conferenceId,
  };
}
