import { combineReducers } from 'redux';
import app from './app/appReducer';
import user from './user/userReducer';
import conferences from './conferences/conferencesReducer';
import newConference from './newConference/newConferenceReducer';


const rootReducer = combineReducers({
  app,
  user,
  conferences,
  newConference,
});

export default rootReducer;
