import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu, Icon } from 'antd';
import './conferencecard.scss';


export class ConferenceCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleAction = this.handleAction.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderDropdownMenu = this.renderDropdownMenu.bind(this);
  }

  handleAction(e) {
    this.props.onClick(this.props.conferenceId, e.target.dataset.action);
  }

  renderButtons(actionButtons) {
    const buttons = [];

    actionButtons.forEach((button) => {
      buttons.push(
        <Button
          key={button.action}
          data-action={button.action}
          onClick={this.handleAction}
        >
          {button.label}
        </Button>,
      );
    });

    return buttons;
  }

  renderDropdownMenu(actionButtons) {
    return (
      <Menu
        onClick={(selected) => {
          this.props.onClick(this.props.conferenceId, selected.key);
        }}
      >
        {actionButtons.map(actionButton => (
          <Menu.Item
            key={actionButton.action}
          >
            {actionButton.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  }

  render() {
    return (
      <div className="conference-card">
        {/*
          <div className="col-logo">#</div>
        */}
        <div className="col-title">
          <div className="title">
            {this.props.title}
          </div>
        </div>
        <div className="col-places">
          <div className="title">{this.props.places}</div>
        </div>
        <div className="col-places">
          <div className="title">{this.props.reserved}</div>
        </div>
        <div className="col-actions">
          {this.props.withDropdown
            ? (
              <Dropdown
                overlay={this.renderDropdownMenu(this.props.actionButtons)}
              >
                <Button style={{ marginLeft: 8 }}>
                  <span>Műveletek</span>
                  <Icon type="down" />
                </Button>
              </Dropdown>
            )
            : this.renderButtons(this.props.actionButtons)
          }
        </div>
      </div>
    );
  }
}

ConferenceCard.defaultProps = {
  actionButtons: [],
};

ConferenceCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  conferenceId: PropTypes.number.isRequired,
  withDropdown: PropTypes.bool.isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string.isRequired,
  places: PropTypes.number.isRequired,
  reserved: PropTypes.number.isRequired,
};

export default ConferenceCard;
