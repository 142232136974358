import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import ConferenceCard from 'components/ConferenceCard/ConferenceCard';
import './conferenceboard.scss';

const titles = {
  1: 'Tanuló turnusok',
  2: 'Kísérő turnusok',
  3: 'Előregisztrációs turnusok',
};


const ConferenceBoard = ({ conferences, tab, actionButtons, withDropdown, onClick }) => (
  <div className="conference-board">
    <h2>{titles[tab]}</h2>

    <Alert
      message="Esemény lista"
      description={`
        A táblázat a jelenleg nyitott turnusokat listázza.\n
        A részletek megismeréséhez, valamint jelentkezéshez kérjük kattintson az Adatlap gombra.
      `}
      type="info"
      showIcon
    />

    <div className="board-header">
      {/*
        <div className="col-logo" />
      */}
      <div className="col-title">Cím</div>
      <div className="col-places">Szabad helyek</div>
      <div className="col-places">Foglalt helyek</div>
      <div className="col-actions" />
    </div>

    <div className="conference-cards">
      {conferences.filter(conference => conference.tab === tab.toString()).map(conference => (
        <ConferenceCard
          key={conference.id}
          conferenceId={parseInt(conference.id, 10)}
          title={conference.name}
          places={parseInt(conference.free, 10)}
          reserved={parseInt(conference.tickets, 10) - parseInt(conference.free, 10)}
          actionButtons={conference.erasable === '1'
            ? [...actionButtons, { label: 'Törlés', action: 'delete' }]
            : actionButtons
          }
          withDropdown={withDropdown}
          onClick={onClick}
        />
      ))}
    </div>
  </div>
);

ConferenceBoard.defaultProps = {
  actionButtons: [],
  withDropdown: false,
};

ConferenceBoard.propTypes = {
  tab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  conferences: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  actionButtons: PropTypes.arrayOf(PropTypes.shape()),
  withDropdown: PropTypes.bool,
};

export default ConferenceBoard;
