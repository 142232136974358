import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Col, Form, Input, Row } from 'antd';
import { login } from 'actions/userActions';
import './login-container.scss';

const querystring = (querystringName, url = window.location.href) => {
  const name = querystringName.replace(/[[]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};


export class LoginForm extends Component {
  resetPasswordField = () => {
    this.props.form.setFieldsValue({ password: '' });
  }

  handleInputChange = (e) => {
    const { dataset: { field }, value } = e.target;
    this.setState({
      [field]: value,
    });
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatchLogin(values.email, values.password, querystring('redirect', this.props.search));
      }
    });
  }

  hasErrors = fieldsError => (
    Object.keys(fieldsError).some(field => fieldsError[field])
  )

  render() {
    const {
      getFieldDecorator, getFieldError, getFieldsError,
    } = this.props.form;
    // Only show error after a field is touched.
    const emailError = getFieldError('email');
    const passwordError = getFieldError('password');


    return (
      <div className="login-container">
        <Form onSubmit={this.handleLogin}>
          <Row gutter={24}>
            <Col span={24}>
              <div className="input-label">{i18next.t('pages.login.email')}</div>
              <Form.Item
                validateStatus={emailError ? 'error' : ''}
                help={emailError || ''}
              >
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: i18next.t('pages.login.errors.emptyEmail') }],
                })(
                  <Input />,
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <div className="input-label">{i18next.t('pages.login.password')}</div>
              <Form.Item
                validateStatus={passwordError ? 'error' : ''}
                help={passwordError || ''}
              >
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: i18next.t('pages.login.errors.emptyPassword') }],
                })(
                  <Input
                    type="password"
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-button"
                  loading={this.props.isAuthenticating}
                  disabled={this.props.isAuthenticating || this.hasErrors(getFieldsError())}
                  block
                >
                  {i18next.t('pages.login.login')}
                </Button>

                <div className="action-block">
                  {i18next.t('pages.login.info')}
                </div>

                <Link to="/registration">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                  >
                    {i18next.t('pages.login.registration')}
                  </Button>
                </Link>
                <Link className="password-reset" to="/forgotten-password">{i18next.t('pages.login.passReset')}</Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  search: PropTypes.string,
  isAuthenticating: PropTypes.bool.isRequired,
  dispatchLogin: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldsError: PropTypes.func,
    getFieldError: PropTypes.func,
    validateFields: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    getFieldsValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    setFields: PropTypes.func,
  }).isRequired,
};

LoginForm.defaultProps = {
  search: '',
};

const mapStateToProps = store => ({
  isAuthenticating: store.user.isAuthenticating,
  errorMessage: store.user.errorMessage,
});

const mapDispatchToProps = {
  dispatchLogin: login,
};

const LoginContainer = Form.create({ name: 'loginForm' })(LoginForm);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoginContainer));
