import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import DropzoneComponent from 'react-dropzone-component';
import { host } from 'store/store';
import './conferenceattachment.scss';


class ConferenceAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.handleComplete = this.handleComplete.bind(this);
  }

  handleComplete(complete) {
    if (complete.status === 'success') {
      this.props.onComplete(
        this.props.id,
        complete.previewElement.children[0].innerHTML,
      );
    }
  }

  render() {
    const componentConfig = {
      iconFiletypes: ['.jpg', '.jpeg', '.png', '.pdf'],
      showFiletypeIcon: true,
      postUrl: `${host}/files/upload`,
    };

    const djsConfig = {
      maxFiles: 1,
      addRemoveLinks: true,
      dictRemoveFile: `
        A feltöltött fájl nem felelt meg a követelményeknek.
        Kattintson ide a fájl törléséhez és új fájl feltöltéséhez.
      `,
      dictDefaultMessage: 'Húzza a keretbe a feltöltendő fájlt vagy kattintson ide a fájl kiválasztásához',
      params: {
        conference_id: this.props.conferenceId,
        user_token: `Bearer ${localStorage.getItem('token')}`,
        field: this.props.id,
        application_id: this.props.applicationId,
      },
    };

    const eventHandlers = {
      complete: (complete) => {
        this.handleComplete(complete);
      },
      error: (file) => {
        console.log(file); // eslint-disable-line
      },
    };

    const { title, isRequired } = this.props;

    return (
      <div className="conference-attachment">
        <div className="conference-attachment-header">
          <div className="attachment-title">
            {title}
            {isRequired
              ? (
                <Tooltip placement="right" title="Kötelező melléklet">
                  <span className="attachment-required">*</span>
                </Tooltip>
              )
              : null
            }
          </div>
        </div>

        <div className="conference-attachment-body">
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          />
        </div>
      </div>
    );
  }
}

ConferenceAttachment.defaultProps = {
  applicationId: null,
};

ConferenceAttachment.propTypes = {
  conferenceId: PropTypes.number.isRequired,
  applicationId: PropTypes.number,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default ConferenceAttachment;
