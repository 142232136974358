import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Icon, Switch, Popconfirm } from 'antd';
import './attachmentblock.scss';

const { TextArea } = Input;


class AttachmentBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.attachment.id,
      title: this.props.attachment.title,
      isRequired: this.props.attachment.isRequired,
      isSaved: this.props.attachment.isSaved,
    };

    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleRequiredChange = this.handleRequiredChange.bind(this);
    this.updateAttachment = this.updateAttachment.bind(this);
  }

  handleTitleChange(e) {
    this.setState({
      title: e.target.value,
    }, () => {
      this.updateAttachment();
    });
  }

  handleRequiredChange(isRequired) {
    this.setState({
      isRequired,
    }, () => {
      this.updateAttachment();
    });
  }

  updateAttachment() {
    const { id, title, isRequired, isSaved } = this.state;
    this.props.updateAttachment(
      this.state.id,
      { id, title, isRequired, isSaved },
    );
  }

  render() {
    const { id, title, isRequired } = this.state;
    const { isFirst, isLast, moveAttachment, deleteAttachment } = this.props;

    return (
      <div className="attachment-block">
        <TextArea
          className="attachment-title"
          value={title}
          onChange={e => this.handleTitleChange(e)}
          autosize
        />

        <div className="is-required">
          <button
            type="button"
            className="switch-button"
            onClick={() => {
              this.handleRequiredChange(!isRequired);
            }}
          >
            <span className="switch-label">Kötelező mező?</span>
            <Switch
              checked={isRequired}
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="cross" />}
            />
          </button>
        </div>

        <div className="attachment-actions">
          <Button
            className="attachment-action-button"
            disabled={isFirst}
            onClick={() => {
              moveAttachment(id, -1);
            }}
          >
            <Icon type="up-square-o" />
          </Button>

          <Button
            className="attachment-action-button"
            disabled={isLast}
            onClick={() => {
              moveAttachment(id, 1);
            }}
          >
            <Icon type="down-square-o" />
          </Button>

          <Popconfirm
            className="attachment-action-button"
            title="Biztosan törölni szeretné ezt a mezőt?"
            onConfirm={() => {
              deleteAttachment(id);
            }}
            okText="Törlés"
            cancelText="Mégsem"
            placement="bottomRight"
          >
            <Button className="attachment-action-button">
              <Icon type="close-square-o" />
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

AttachmentBlock.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  attachment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isSaved: PropTypes.bool.isRequired,
  }).isRequired,
  moveAttachment: PropTypes.func.isRequired,
  updateAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
};

export default AttachmentBlock;
