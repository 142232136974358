import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import QuestionBlock from './QuestionBlock/QuestionBlock';
import './questionnaire.scss';


class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddQuestion = this.handleAddQuestion.bind(this);
  }

  handleAddQuestion() {
    this.props.addQuestion({
      id: new Date().getTime(),
      title: '',
      type: 'text',
      options: [],
      isRequired: false,
      isSaved: false,
    });
  }

  render() {
    return (
      <div className="form-questionnaire">
        {this.props.questions.map((question, id) => (
          <QuestionBlock
            key={question.id}
            question={question}
            isFirst={id === 0}
            isLast={id === this.props.questions.length - 1}
            updateQuestion={this.props.updateQuestion}
            moveQuestion={this.props.moveQuestion}
            deleteQuestion={this.props.deleteQuestion}
            deleteOption={this.props.deleteOption}
          />
        ))}

        <div className="form-questionnaire-action">
          <Button
            size="large"
            type="dashed"
            className="add-new-question"
            onClick={this.handleAddQuestion}
          >
            Új kérdés hozzáadása
          </Button>
        </div>
      </div>
    );
  }
}

Questionnaire.defaultProps = {
  questions: [],
};

Questionnaire.propTypes = {
  addQuestion: PropTypes.func.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  deleteOption: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape()),
};

export default Questionnaire;
