import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginLayout from 'layouts/LoginLayout/LoginLayout';
import AppLayout from 'layouts/AppLayout/AppLayout';
import LoginPage from 'routes/Login/Login';
import DashboardPage from 'routes/Dashboard/Dashboard';
import AdminPage from 'routes/Admin/Admin';
import ConferenceAdminProfilePage from 'routes/ConferenceAdminProfile/ConferenceAdminProfile';
import ConferenceAdminUpdateApplyPage from 'routes/ConferenceAdminUpdateApply/ConferenceAdminApply';
import ConferenceProfilePage from 'routes/ConferenceProfile/ConferenceProfile';
import ConferenceApplyPage from 'routes/ConferenceApply/ConferenceApply';
import EditConferencePage from 'routes/EditConference/EditConference';
import AddConferencePage from 'routes/AddConference/AddConference';
import ChangePasswordPage from 'routes/ChangePassword/ChangePassword';
import RegistrationPage from 'routes/Registration/Registration';
import ForgottenPasswordPage from 'routes/ForgottenPassword/ForgottenPassword';
import ForgottenPasswordSentPage from 'routes/ForgottenPasswordSent/ForgottenPasswordSent';
import Page404 from 'routes/Page404/Page404';
import requireAuth from './Auth';


const AppRoute = ({ component: Component, ...rest }) => (
  <AppLayout>
    <Route {...rest} component={requireAuth(Component)} />
  </AppLayout>
);

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

const PublicRoute = ({ layout, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (React.createElement(layout, props, <Component {...props} />))}
  />
);

PublicRoute.propTypes = {
  layout: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
};

const App = () => (
  <Switch>
    <Redirect exact from="/" to="/turnusok" />
    <Redirect exact from="/dashboard" to="/turnusok" />
    <PublicRoute exact path="/login" layout={LoginLayout} component={LoginPage} />
    <PublicRoute exact path="/registration" layout={LoginLayout} component={RegistrationPage} />
    <PublicRoute exact path="/forgotten-password" layout={LoginLayout} component={ForgottenPasswordPage} />
    <PublicRoute exact path="/forgotten-password/sent" layout={LoginLayout} component={ForgottenPasswordSentPage} />
    <AppRoute exact path="/turnusok" component={DashboardPage} />
    <Redirect exact path="/eloregisztracio" to="/turnusok" />
    <AppRoute exact path="/admin/conferences" component={AdminPage} />
    <AppRoute exact path="/admin/conferences/:id/profile" component={ConferenceAdminProfilePage} />
    <AppRoute exact path="/admin/conferences/:id/applications/:applicationId" component={ConferenceAdminUpdateApplyPage} />
    <AppRoute exact path="/admin/conferences/:id/edit" component={EditConferencePage} />
    <AppRoute exact path="/admin/conferences/create" component={AddConferencePage} />
    <AppRoute exact path="/conferences/:id" component={ConferenceProfilePage} />
    <AppRoute exact path="/conferences/:id/applications/:applicationId" component={ConferenceApplyPage} />
    <AppRoute exact path="/profile/change-password" component={ChangePasswordPage} />
    <Route component={Page404} />
  </Switch>
);

export default App;
