const hu = {
  lang: {
    placeholder: 'Dátum választása',
    rangePlaceholder: [
      'A regisztráció kezdete',
      'A regisztráció vége',
    ],
    today: 'Ma',
    now: 'Most',
    backToToday: 'Vissza a mai naphoz',
    ok: 'Ok',
    clear: 'Törlés',
    month: 'Month',
    year: 'Year',
    timeSelect: 'Időpont választása',
    dateSelect: 'Dátum választása',
    monthSelect: 'Hónap választása',
    yearSelect: 'Év választása',
    decadeSelect: 'Évtized választása',
    yearFormat: 'YYYY',
    dateFormat: 'YYYY.MM.DD',
    dayFormat: 'D',
    dateTimeFormat: 'YYYY.MM.DD HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: false,
    previousMonth: 'Előző hónap',
    nextMonth: 'Következő hónap',
    previousYear: 'Előző év',
    nextYear: 'Következő év',
    previousDecade: 'Elmúlt évtized',
    nextDecade: 'Következő évtized',
    previousCentury: 'Előző évszázad',
    nextCentury: 'Következő évszázad',
  },
  timePickerLocale: {
    placeholder: 'Select time',
  },
};

export default hu;
