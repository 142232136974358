import {
  NEWCONFERENCE_NEXT_STEP,
  NEWCONFERENCE_PREV_STEP,
  NEWCONFERENCE_RESET_REQUESTED,
  NEWCONFERENCE_CREATE_REQUESTED,
  NEWCONFERENCE_UPDATE_REQUESTED,
  NEWCONFERENCE_CHANGE_ATTRIBUTE,
  NEWCONFERENCE_ADD_QUESTION,
  NEWCONFERENCE_UPDATE_QUESTION,
  NEWCONFERENCE_DELETE_QUESTION_REQUESTED,
  NEWCONFERENCE_MOVE_QUESTION,
  NEWCONFERENCE_ADD_ATTACHMENT,
  NEWCONFERENCE_UPDATE_ATTACHMENT,
  NEWCONFERENCE_DELETE_ATTACHMENT_REQUESTED,
  NEWCONFERENCE_MOVE_ATTACHMENT,
  NEWCONFERENCE_DELETE_OPTION_REQUESTED,
  NEWCONFERENCE_SUBMIT_REQUESTED,
  NEWCONFERENCE_UPDATE_FORM_REQUESTED,
} from 'consts/actionTypes';

export function nextStep() {
  return {
    type: NEWCONFERENCE_NEXT_STEP,
  };
}

export function prevStep() {
  return {
    type: NEWCONFERENCE_PREV_STEP,
  };
}

export function resetConferenceFields() {
  return {
    type: NEWCONFERENCE_RESET_REQUESTED,
  };
}

export function createConference(fields) {
  return {
    type: NEWCONFERENCE_CREATE_REQUESTED,
    fields,
  };
}

export function updateConference(fields) {
  return {
    type: NEWCONFERENCE_UPDATE_REQUESTED,
    fields,
  };
}

export function changeAttribute(attribute, payload) {
  return {
    type: NEWCONFERENCE_CHANGE_ATTRIBUTE,
    attribute,
    payload,
  };
}

export function addQuestion(question) {
  return {
    type: NEWCONFERENCE_ADD_QUESTION,
    question,
  };
}

export function updateQuestion(id, question) {
  return {
    type: NEWCONFERENCE_UPDATE_QUESTION,
    id,
    question,
  };
}

export function deleteQuestion(id) {
  return {
    type: NEWCONFERENCE_DELETE_QUESTION_REQUESTED,
    id,
  };
}

export function moveQuestion(id, direction) {
  return {
    type: NEWCONFERENCE_MOVE_QUESTION,
    id,
    direction,
  };
}

export function addAttachment(attachment) {
  return {
    type: NEWCONFERENCE_ADD_ATTACHMENT,
    attachment,
  };
}

export function updateAttachment(id, attachment) {
  return {
    type: NEWCONFERENCE_UPDATE_ATTACHMENT,
    id,
    attachment,
  };
}

export function deleteAttachment(id) {
  return {
    type: NEWCONFERENCE_DELETE_ATTACHMENT_REQUESTED,
    id,
  };
}

export function moveAttachment(id, direction) {
  return {
    type: NEWCONFERENCE_MOVE_ATTACHMENT,
    id,
    direction,
  };
}

export function deleteOption(id) {
  return {
    type: NEWCONFERENCE_DELETE_OPTION_REQUESTED,
    id,
  };
}

export function submitConference() {
  return {
    type: NEWCONFERENCE_SUBMIT_REQUESTED,
  };
}

export function updateConferenceForm() {
  return {
    type: NEWCONFERENCE_UPDATE_FORM_REQUESTED,
  };
}
