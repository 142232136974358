import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Icon, Popconfirm } from 'antd';

const { TextArea } = Input;


const QuestionBlockHeader = ({
  title, isFirst, isLast, id, questionId,
  handleTitleChange, moveQuestion, deleteQuestion,
}) => (
  <div className="block-header">
    <TextArea
      className="question-title"
      value={title}
      onChange={e => handleTitleChange(e)}
      autosize
    />
    <div className="question-actions">
      <Button
        className="question-action-button"
        disabled={isFirst}
        onClick={() => {
          moveQuestion(id, -1);
        }}
      >
        <Icon type="up-square-o" />
      </Button>

      <Button
        className="question-action-button"
        disabled={isLast}
        onClick={() => {
          moveQuestion(id, 1);
        }}
      >
        <Icon type="down-square-o" />
      </Button>

      <Popconfirm
        className="question-action-button"
        title="Biztosan törölni szeretné ezt a kérdést?"
        onConfirm={() => {
          deleteQuestion(questionId);
        }}
        okText="Törlés"
        cancelText="Mégsem"
        placement="bottomRight"
      >
        <Button className="question-action-button">
          <Icon type="close-square-o" />
        </Button>
      </Popconfirm>
    </div>
  </div>
);

QuestionBlockHeader.propTypes = {
  handleTitleChange: PropTypes.func.isRequired,
  moveQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default QuestionBlockHeader;
