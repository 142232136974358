import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert, Input, InputNumber, DatePicker, Select, Switch } from 'antd';
import FroalaEditorBox from 'components/FroalaEditorBox/FroalaEditorBox';
import * as datePickerLocales from 'locales/datePickerLocales';

const { Option } = Select;


class ConferenceForm extends React.Component {
  handleChange = (attribute, payload) => {
    this.props.handleFieldChange(attribute, payload);
  }

  render() {
    return (
      <div className="conference-form">
        <div className="form-row">
          <h2>Az esemény címe</h2>
          <Input
            data-field="name"
            className="conference-title-input"
            value={this.props.title}
            onChange={(e) => {
              this.handleChange('title', e.target.value);
            }}
            ref={(input) => { this.titleInput = input; }}
          />
        </div>

        <div className="form-row">
          <h2>Regisztrációs kategória</h2>
          <Select
            className="conference-select-input"
            data-field="tab"
            value={this.props.tab}
            style={{ width: '100%' }}
            onChange={(value) => {
              this.handleChange('tab', value);
            }}
          >
            <Option value="1">tanuló</Option>
            <Option value="2">kísérő</Option>
            <Option value="3">Előregisztráció</Option>
          </Select>
        </div>

        <div className="form-row">
          <h2>Az esemény leírása</h2>
          <FroalaEditorBox
            field="description"
            model={this.props.description}
            handleChange={this.handleChange}
          />
        </div>

        <div className="form-row">
          <h2>Egyéb tudnivalók</h2>
          <FroalaEditorBox
            field="prices_dates"
            model={this.props.prices_dates}
            handleChange={this.handleChange}
          />
        </div>

        {
          /*
          <div className="form-row">
            <h2>Program</h2>
            <FroalaEditorBox
              field="schedule"
              model={this.props.schedule}
              handleChange={this.handleChange}
            />
          </div>
          */
        }

        <div className="form-row">
          <h2>Szabad helyek száma</h2>
          <InputNumber
            className="available-tickets"
            size="large"
            min={0}
            max={9998}
            defaultValue={this.props.availableTickets}
            onChange={(number) => {
              this.handleChange(
                'availableTickets',
                Number.isInteger(number) ? number : null,
              );
            }}
          />
        </div>

        <div className="form-row">
          <h2>A betegség szekció látszik</h2>
          <div className="form-boolean">
            <div className="boolean-item">
              <Switch
                defaultChecked={this.props.sicknessVisible}
                onChange={(checked) => { this.handleChange('sicknessVisible', checked); }}
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <h2>A regisztráció időtartama</h2>
          {/*
          <div className="form-boolean">
            <div className="boolean-item">
              <h3>Előregisztráció megengedett:</h3>
              <Switch
                defaultChecked={this.props.isPreReg}
                onChange={(checked) => { this.handleChange('isPreReg', checked); }}
              />
            </div>
          </div>
          */}

          <div className="form-date-picker">
            <div className="picker-item">
              <h3>Kezdete</h3>
              <DatePicker
                showTime
                value={this.props.regOpen}
                format="YYYY.MM.DD"
                locale={datePickerLocales.default}
                size="large"
                onChange={(date) => {
                  const timeStamp = date === null ? null : moment(date).format('YYYY-MM-DD HH:mm:ss');
                  this.handleChange('regOpen', timeStamp);
                }}
              />
            </div>

            <div className="picker-item">
              <h3>Vége</h3>
              <DatePicker
                showTime
                value={this.props.regClose}
                format="YYYY.MM.DD"
                locale={datePickerLocales.default}
                size="large"
                onChange={(date) => {
                  const timeStamp = date === null ? null : moment(date).format('YYYY-MM-DD HH:mm:ss');
                  this.handleChange('regClose', timeStamp);
                }}
              />
            </div>
          </div>

          <div className="form-date-picker">
            <div className="picker-item">
              <h3>Értesítések kezdete</h3>
              <DatePicker
                showTime
                value={this.props.notificationOpen}
                format="YYYY.MM.DD"
                locale={datePickerLocales.default}
                size="large"
                onChange={(date) => {
                  const timeStamp = date === null ? null : moment(date).format('YYYY-MM-DD HH:mm:ss');
                  this.handleChange('notificationOpen', timeStamp);
                }}
              />
            </div>

            <div className="picker-item">
              <h3>Értesítések vége</h3>
              <DatePicker
                showTime
                value={this.props.notificationClose}
                format="YYYY.MM.DD"
                locale={datePickerLocales.default}
                size="large"
                onChange={(date) => {
                  const timeStamp = date === null ? null : moment(date).format('YYYY-MM-DD HH:mm:ss');
                  this.handleChange('notificationClose', timeStamp);
                }}
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <h2>A kiküldendő e-mail szövege</h2>
          <Alert message="A név szerinti megszólítás automatikusan hozzáfűzésre kerül." type="info" showIcon />
          <FroalaEditorBox
            field="emailString"
            model={this.props.emailString}
            handleChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}

ConferenceForm.defaultProps = {
  tab: '1',
  title: '',
  description: '',
  prices_dates: '',
  regOpen: null,
  regClose: null,
  notificationOpen: null,
  notificationClose: null,
  availableTickets: null,
  emailString: '',
  sicknessVisible: false,
};

ConferenceForm.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  tab: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  prices_dates: PropTypes.string,
  regOpen: PropTypes.shape(),
  regClose: PropTypes.shape(),
  notificationOpen: PropTypes.shape(),
  notificationClose: PropTypes.shape(),
  availableTickets: PropTypes.number,
  emailString: PropTypes.string,
  sicknessVisible: PropTypes.bool,
};

export default ConferenceForm;
