import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Modal, Table, Tabs } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { history } from 'store/store';
import {
  createApplicationSlot,
  getApplications,
  getAdminConferences,
  getConference,
  editConference,
  deleteConference,
  cancelApplication,
} from 'actions/appActions';
import './conferenceadminprofile.scss';

const { TabPane } = Tabs;
const tabs = {
  1: 'Tanuló',
  2: 'Kísérő',
  3: 'Képviselő',
};

class ConferenceAdminProfile extends React.Component {
  constructor(props) {
    super(props);

    this.props.dispatchGetApplications(this.props.match.params.id);

    let conference;
    let currentProfile = null;

    if (this.props.adminconferences === null) {
      this.props.dispatchGetAdminConferences();
    } else {
      conference = _.find(this.props.adminconferences, { id: this.props.match.params.id });

      if (conference === undefined) {
        history.push('/admin/conferences');
      }
    }

    if (this.props.currentProfile !== null) {
      if (this.props.currentProfile.id !== this.props.match.params.id) {
        this.props.dispatchGetConference(this.props.match.params.id);
      } else {
        ({ currentProfile } = this.props);
      }
    } else {
      this.props.dispatchGetConference(this.props.match.params.id);
    }

    const { tab } = queryString.parse(this.props.location.search);

    this.state = {
      currentProfile,
      activeKey: tab || 'informations',
      currentApplications: this.props.currentApplications,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentProfile !== this.state.currentProfile) {
      this.setState({
        currentProfile: nextProps.currentProfile,
      });
    }

    if (nextProps.currentApplications !== this.state.currentApplications) {
      this.setState({
        currentApplications: nextProps.currentApplications,
      });
    }

    if (nextProps.adminconferences !== this.props.adminconferences) {
      const conference = _.find(nextProps.adminconferences, { id: this.props.match.params.id });
      if (conference === undefined) {
        history.push('/admin/conferences');
      }
    }
  }

  handleEditClick() {
    this.props.dispatchEditConference(this.state.currentProfile.id);
  }

  render() {
    if (!this.props.user.admin) {
      history.push('/turnusok');
    }

    if (this.state.currentProfile === null) return null;

    const {
      name,
      description,
      prices_dates, // eslint-disable-line
      preregdata,
    } = this.state.currentProfile;

    const columns = [
      {
        title: `${tabs[this.state.currentProfile.tab]} vezetékneve`,
        dataIndex: 'vezeteknev',
        key: 'vezeteknev',
      },
      {
        title: `${tabs[this.state.currentProfile.tab]} keresztneve`,
        dataIndex: 'keresztnev',
        key: 'keresztnev',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Jelentkezés ideje',
        dataIndex: 'date',
        key: 'date',
        render: (text, row) => (
          <span key="date">
            {moment(row.date).format('YYYY.MM.DD HH:mm:ss')}
          </span>
        ),
      },
      {
        title: 'Műveletek',
        key: 'action',
        render: (text, row) => (
          [
            <span key="edit">
              <Button
                onClick={() => {
                  history.push(`/admin/conferences/${this.props.match.params.id}/applications/${row.id}`);
                }}
              >
                Szerkesztés
              </Button>
            </span>,
            <span key="delete">
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: 'Biztosan törölni szeretné ezt a jelentkezést?',
                    okText: 'Jelentkezés törlése',
                    cancelText: 'Mégsem',
                    onOk: () => {
                      this.props.dispatchCancelApplication(this.props.match.params.id, row.id, true);
                    },
                  });
                }}
              >
                Törlés
              </Button>
            </span>,
          ]
        ),
      },
    ];

    return (
      <div className="page page-conference-admin-profile">
        <div className="prereg-info-header">
          <div className="block-title">
            Aktuális előjelentkezések száma turnusonként
          </div>
          <div>(tájékoztató adatok)</div>
        </div>
        <div className="prereg-info-cards">
          {
            preregdata
            && preregdata.map(item => (
              <div key={item.turnus_id} className="prereg-info-card-container">
                <div className="prereg-info-card">
                  <div className="title">{item.title}</div>
                  <div className="body">{item.value || 0}</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="page-header">
          <div className="page-header-wrap">Tábor adatlap</div>
        </div>

        <div className="page-action-button">
          <Button
            type="primary"
            icon="edit"
            size="large"
            onClick={this.handleEditClick}
          >
            Esemény szerkesztése
          </Button>
        </div>

        <div className="page-content">
          <div className="page-content-wrap">
            <h1 className="page-title">{name}</h1>

            <Link to="/admin/conferences">
              <Button icon="left">Vissza</Button>
            </Link>

            <Tabs
              activeKey={this.state.activeKey}
              onChange={(activeKey) => {
                this.setState({
                  activeKey,
                });
              }}
            >
              <TabPane tab="Információk az eseményről" key="informations">
                <div className="conference-summary">
                  <div className="basic-information">
                    <div className="summary-block">
                      <h2 className="block-title">
                        Az esemény leírása
                      </h2>
                      <div
                        className="block-content"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    </div>

                    <div className="summary-block">
                      <h2 className="block-title">
                        Egyéb tudnivalók
                      </h2>
                      <div
                        className="block-content"
                        dangerouslySetInnerHTML={{ __html: prices_dates }}
                      />
                    </div>

                    {/*
                      <div className="summary-block">
                        <h2 className="block-title">Program</h2>
                        <div
                          className="block-content"
                          dangerouslySetInnerHTML={{ __html: schedule }}
                        />
                      </div>
                    */}
                  </div>
                </div>

                {
                  this.state.currentProfile.erasable === '1'
                  && (
                    <div className="conference-actions">
                      <Button
                        type="danger"
                        onClick={() => {
                          Modal.confirm({
                            title: 'Biztosan törölni szeretné ezt az eseményt?',
                            content: 'Az esemény törlésével elvesznek a kérdőívhez benyújtott válaszok is.',
                            okText: 'Esemény törlése',
                            cancelText: 'Mégsem',
                            onOk: () => {
                              this.props.dispatchDeleteConference(this.state.currentProfile.id);
                            },
                          });
                        }}
                      >
                        Esemény törlése
                      </Button>
                    </div>
                  )
                }
              </TabPane>

              <TabPane tab="Az eseményre jelentkezettek listája" key="applications">
                {this.state.currentApplications === null
                  ? null
                  : (
                    <Table
                      dataSource={this.state.currentApplications}
                      columns={columns}
                      pagination={false}

                    />
                  )
                }
                <div className="conference-actions">
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.dispatchCreateApplicationSlot({
                        id: this.state.currentProfile.id,
                      });
                    }}
                  >
                    Új jelentkezés rögzítése
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

ConferenceAdminProfile.defaultProps = {
  adminconferences: null,
  currentProfile: null,
  user: {
    admin: false,
  },
  currentApplications: null,
  location: {
    query: {
      tab: 'informations',
    },
  },
};

ConferenceAdminProfile.propTypes = {
  dispatchCreateApplicationSlot: PropTypes.func.isRequired,
  dispatchGetApplications: PropTypes.func.isRequired,
  dispatchGetAdminConferences: PropTypes.func.isRequired,
  dispatchGetConference: PropTypes.func.isRequired,
  dispatchEditConference: PropTypes.func.isRequired,
  dispatchDeleteConference: PropTypes.func.isRequired,
  dispatchCancelApplication: PropTypes.func.isRequired,
  adminconferences: PropTypes.arrayOf(PropTypes.shape()),
  user: PropTypes.shape({
    admin: PropTypes.bool,
  }),
  currentProfile: PropTypes.shape({
    id: PropTypes.string,
  }),
  currentApplications: PropTypes.arrayOf(PropTypes.shape()),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    query: PropTypes.shape({
      tab: PropTypes.string,
    }),
  }),
};

const mapStateToProps = store => ({
  adminconferences: store.conferences.adminconferences,
  currentProfile: store.conferences.currentProfile,
  currentApplications: store.conferences.currentApplications,
});

const mapDispatchToProps = {
  dispatchCreateApplicationSlot: createApplicationSlot,
  dispatchGetApplications: getApplications,
  dispatchGetAdminConferences: getAdminConferences,
  dispatchGetConference: getConference,
  dispatchEditConference: editConference,
  dispatchDeleteConference: deleteConference,
  dispatchCancelApplication: cancelApplication,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceAdminProfile);
