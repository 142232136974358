import swal from 'sweetalert';

export function parseJSON(response) {
  return response.json();
}

export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return parseJSON(response).then((err) => {
    switch (err.message) {
      case 'Unauthorized': {
        break;
      }

      default: {
        swal({
          title: 'Hiba!',
          text: err.message,
          icon: 'error',
          button: {
            text: 'OK',
          },
        });
      }
    }

    const error = new Error(err.message);
    error.response = response;
    throw error;
  });
}


export function animationDelay(index) {
  return `${(Math.acosh(index + 3) - 1.5)}s`;
}

export function getKepviseloIds(currentId) {
  return ['111', '139', '158'].includes(currentId);
}
