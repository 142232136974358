import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import LoginContainer from 'containers/Login/LoginContainer';
import TaborCsoport from './tabor-csoport-ujkep.png';
import './login.scss';


export class LoginPage extends Component {
  state = {};

  render() {
    return (
      <div className="page page-login">
        <div className="page-header">
          <div className="page-header-wrap">Bejelentkezés</div>
        </div>
        <div className="page-content">
          <div className="page-content-wrap">
            <div className="login-content">
              <LoginContainer search={this.props.location.search} />
              <img src={TaborCsoport} alt="tábor csoportkép" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withTranslation()(LoginPage);
