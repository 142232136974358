const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isAuthenticatingError: false,
  authenticatingErrorMessage: '',
  access_token: null,
  email: null,
  uid: null,
  admin: false,
};

export default initialState;
