import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Alert, Button, Form, Input } from 'antd';
import * as appActions from 'actions/appActions';

const FormItem = Form.Item;


class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);

    this.getFieldDecorator = this.props.form.getFieldDecorator;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.resetPassword(values.email);
      }
    });
  }

  render() {
    return (
      <div className="page page-forgotten-password page-slim">
        <Alert
          message="Jelszó helyreállítása"
          description="Adja meg a bejelentkezéshez használt email címét."
          type="info"
          className="password-reset-alert"
          showIcon
        />

        <Form onSubmit={this.handleSubmit} className="password-reset-form">
          <FormItem label={i18next.t('pages.signup.email')}>
            {this.getFieldDecorator('email', {
              rules: [{
                type: 'email',
                required: true,
                message: i18next.t('pages.signup.required.the_input_is_not_valid_e_mail'),
              }],
            })(
              <Input />,
            )}
          </FormItem>

          <FormItem className="button-wrap">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ width: '100%' }}
            >
              {i18next.t('pages.profile.forgotten.restore')}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

ForgottenPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  form: PropTypes.shape({
    validateFieldsAndScroll: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  resetPassword: appActions.resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ForgottenPassword));
