import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker, Checkbox, Select, Input, Alert, Icon, Tooltip } from 'antd';
import * as datePickerLocales from 'locales/datePickerLocales';
import './summary.scss';

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { TextArea } = Input;


class Summary extends React.Component {
  renderQuestionContent = (type, options) => {
    switch (type) {
      case 'checkbox': {
        const optionsList = [];
        options.forEach((option) => {
          optionsList.push({
            value: option.id,
            label: option.value,
          });
        });

        return (
          <CheckboxGroup
            options={optionsList}
          />
        );
      }

      case 'date': {
        return (
          <DatePicker
            format="YYYY.MM.DD"
            locale={datePickerLocales.default}
            size="large"
          />
        );
      }

      case 'dropdown': {
        const children = [];
        options.forEach((option) => {
          children.push(<Option key={option.id}>{option.value}</Option>);
        });

        return (
          <Select
            placeholder="Válasszon egy lehetőséget"
            style={{ minWidth: 200 }}
            dropdownClassName="select-dropdown-options"
          >
            {children}
          </Select>
        );
      }

      case 'text': {
        return (
          <Input />
        );
      }

      case 'textarea': {
        return (
          <TextArea autosize={{ minRows: 2 }} />
        );
      }

      default: return `type: ${type}`;
    }
  }

  renderCatagoryName = (tab) => {
    switch (tab) {
      case '1': return 'tanuló';
      case '2': return 'kísérő';
      case '3': return 'Előregisztráció';

      default: return 'tanuló';
    }
  }

  render() {
    const {
      name,
      tab,
      description,
      prices_dates, // eslint-disable-line
      questions,
      attachments,
      availableTickets,
      emailString,
    } = this.props;

    return (
      <div className="conference-summary">
        <div className="basic-information">
          <h1 className="conference-title">{name}</h1>

          <div className="summary-block row-block">
            <h2 className="block-title">Regisztrációs kategória:</h2>
            <div className="block-content">{this.renderCatagoryName(tab)}</div>
          </div>

          <div className="summary-block">
            <h2 className="block-title">
              Az esemény leírása
            </h2>
            <div
              className="block-content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>

          <div className="summary-block">
            <h2 className="block-title">
              Egyéb tudnivalók
            </h2>
            <div
              className="block-content"
              dangerouslySetInnerHTML={{ __html: prices_dates }}
            />
          </div>

          {/*
            <div className="summary-block">
              <h2 className="block-title">Program</h2>
              <div
                className="block-content"
                dangerouslySetInnerHTML={{ __html: schedule }}
              />
            </div>
          */}

          <div className="summary-block row-block">
            <h2 className="block-title">Szabad helyek száma:</h2>
            <div className="block-content">{`${availableTickets} fő`}</div>
          </div>

          <div className="summary-block row-multiline">
            <div className="row-block">
              <h2 className="block-title">Betegség szekció látszik:</h2>
              <div className="block-content">{this.props.sicknessVisible ? 'igen' : 'nem'}</div>
            </div>
          </div>

          <div className="summary-block row-multiline">
            {/*
            <div className="row-block">
              <h2 className="block-title">Előregisztráció megengedett:</h2>
              <div className="block-content">{this.props.isPreReg ? 'igen' : 'nem'}</div>
            </div>
            */}

            <div className="row-block">
              <h2 className="block-title">A regisztráció kezdete:</h2>
              <div className="block-content">
                {moment(this.props.regOpen).format('YYYY. MM. DD., HH:mm:ss')}
              </div>
            </div>
            <div className="row-block">
              <h2 className="block-title">A regisztráció vége:</h2>
              <div className="block-content">
                {moment(this.props.regClose).format('YYYY. MM. DD., HH:mm:ss')}
              </div>
            </div>

            {
              this.props.notificationOpen && this.props.notificationClose
              && (
                <>
                  <div className="row-block">
                    <h2 className="block-title">Értesítések kezdete:</h2>
                    <div className="block-content">
                      {moment(this.props.regOpen).format('YYYY. MM. DD., HH:mm:ss')}
                    </div>
                  </div>
                  <div className="row-block">
                    <h2 className="block-title">Értesítések vége:</h2>
                    <div className="block-content">
                      {moment(this.props.regClose).format('YYYY. MM. DD., HH:mm:ss')}
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </div>

        <Alert
          message="Kérdőív"
          description={
            `Az alábbiakban a kérdőívet tesztelheti.\n
            Válaszok megadására kizárólag ellenőrzés céljából van lehetség,\n
            azok mentésre nem kerülnek, a végleges adatgyűjtésben nem jelennek meg.`
          }
          type="info"
          showIcon
        />

        <div className="form-questions">
          {questions.map(question => (
            <div key={question.id} className="question-block">
              <h2 className="question-title">
                <div>{question.title}</div>
              </h2>
              <div className="question-content">
                {this.renderQuestionContent(question.type, question.options)}
              </div>
            </div>
          ))}
        </div>

        <Alert
          message="Fájlfeltöltés"
          description={
            `A feltöltési mezők az esemény létrehozása során inaktívak.\n
            Fájlok feltöltése csak az adott eseményre jelentkezés során van lehetőség.`
          }
          type="info"
          showIcon
        />

        <div className="form-attachments-summary">
          <h2 className="form-attachments-title">Mellékletek</h2>
          {attachments.map(attachment => (
            <div key={attachment.id} className="attachment-item">
              <Icon type="upload" className="upload-icon" />
              <div className="attachment-title">
                {attachment.title}
                {attachment.isRequired
                  ? (
                    <Tooltip placement="right" title="Kötelező melléklet">
                      <span className="attachment-required">*</span>
                    </Tooltip>
                  )
                  : null
                }
              </div>
            </div>
          ))}
        </div>

        <div className="basic-information">
          <div className="summary-block">
            <h2 className="block-title">A kiküldendő e-mail üzenet szövege</h2>
            <div
              className="block-content"
              dangerouslySetInnerHTML={{ __html: emailString }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Summary.defaultProps = {
  name: '',
  tab: 1,
  description: '',
  prices_dates: '',
  regOpen: '',
  regClose: '',
  notificationOpen: '',
  notificationClose: '',
  sicknessVisible: false,
  availableTickets: 0,
  emailString: '',
  questions: [],
  attachments: [],
};

Summary.propTypes = {
  name: PropTypes.string,
  tab: PropTypes.string,
  description: PropTypes.string,
  prices_dates: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape()),
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  regOpen: PropTypes.shape(),
  regClose: PropTypes.shape(),
  notificationOpen: PropTypes.shape(),
  notificationClose: PropTypes.shape(),
  availableTickets: PropTypes.number,
  sicknessVisible: PropTypes.bool,
  emailString: PropTypes.string,
};

export default Summary;
