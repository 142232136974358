import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { Form, Input, Button } from 'antd';

const FormItem = Form.Item;


class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputType: 'password',
      inputTypeText: i18next.t('pages.signup.show'),
    };

    this.getFieldDecorator = this.props.form.getFieldDecorator;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkConfirm = this.checkConfirm.bind(this);
    this.passwordToggle = this.passwordToggle.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.handleSubmit(values);
      }
    });
  }

  checkConfirm(rule, value, callback) {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  passwordToggle() {
    this.setState(prevState => ({
      inputType: prevState.inputType === 'password' ? 'text' : 'password',
      inputTypeText: prevState.inputTypeText === i18next.t('pages.signup.show')
        ? i18next.t('pages.signup.hide')
        : i18next.t('pages.signup.show'),
    }));
  }

  render() {
    return (
      <div className="normal-login-form">
        <Form onSubmit={this.handleSubmit}>
          <FormItem label={i18next.t('pages.signup.email')}>
            {this.getFieldDecorator('email', {
              rules: [{
                type: 'email',
                required: true,
                message: i18next.t('pages.signup.required.the_input_is_not_valid_e_mail'),
              }],
            })(
              <Input />,
            )}
          </FormItem>

          <FormItem label={i18next.t('pages.signup.password')}>
            {this.getFieldDecorator('password', {
              rules: [{
                required: true,
                message: i18next.t('pages.signup.required.please_input_your_password'),
              }],
            })(
              <Input type={this.state.inputType} />,
            )}
            <Button
              className="passwordToggle"
              name="passwordToggle"
              onClick={this.passwordToggle}
            >
              {this.state.inputTypeText}
            </Button>
          </FormItem>

          <FormItem className="button-wrap">
            <Button type="primary" htmlType="submit" size="large">{i18next.t('pages.signup.signup')}</Button>
            <div className="action-block">
              Amennyiben már rendelkezik rendszerünkben fiókkal,
              <Link to="/">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  kattintson ide a bejelentkezéshez
                </Button>
              </Link>
            </div>
          </FormItem>
        </Form>
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  form: PropTypes.shape({
    validateFieldsAndScroll: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Form.create()(RegistrationForm);
